import React, { useEffect, useState } from 'react';
import {
    TimelineItem,
} from "@mui/lab";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack
} from "@mui/material";
import { 
    LocationOn,
    MiscellaneousServices,
    Today,
    EventAvailable,
    Schedule,
    Person,
    Business,
    CoffeeMaker,
    ReportProblem,
    Person2,
    Notes,
    Engineering,
    TipsAndUpdates,
    SettingsSuggest,
    Build,
    Photo,
    FmdBad,
    EmojiObjects
} from '@mui/icons-material';
import Divider from "@mui/material/Divider";
import moment from 'moment';

const ScheduleItem = ({item}) => {
    const ticketsStatus = ["Aberto", "Técnico a caminho", "Técnico chegou", "Resolvido", "Pendente", "Orfão", "Cancelado"];
    
    const [stagesImages, setStagesImages] = useState({
        OPEN: [],
        ATTENDACCE: [],
        FINISH: []
    });

    const labels = {
        OPEN: "Imagens Abertura",
        ATTENDACCE: "Imagens Atendimento",
        FINISH: "Imagens Fechamento"
    }

    useEffect(() => {
        const stages = {
            OPEN: [],
            ATTENDACCE: [],
            FINISH: []
        };

        if(item.attachments)
            item.attachments.map(attachment => {
                switch(attachment.stage) {
                    case "FINISH":
                        stages.FINISH.push(attachment);
                        break;
                    case "ATTENDACCE":
                        stages.ATTENDACCE.push(attachment);
                        break;
                    default:
                        stages.OPEN.push(attachment);
                        break;
                }
            })

        setStagesImages(stages);
    }, [item]);

    function getTecsList() {
        const list = [];

        if(item.external_user_technicians)
            item.external_user_technicians.map(tec => {
                list.push(`${tec.user.profile.name} ${tec.user.profile.last_name}`);
            })

        return list.length > 0 ? list.join(", ") : "N/A";
    }

    function getPartsList() {
        const list = [];

        if(item.product_model_parts && item.product_model_parts.length > 0)
            item.product_model_parts.map(part => {
                list.push(part.name);
            })

        return list.length > 0 ? list.join(", ") : "N/A";
    }

    function getFamilyName() {
        if(item.product_model)
            return item.product_model.product.product_family.name

        return "N/A";
    }

    function getProductName() {
        if(item.product_model)
            return item.product_model.product.name
        
        return "N/A";
    }

    function getModelName() {
        if(item.product_model)
            return item.product_model.name

        return "N/A";
    }

    return (
        <TimelineItem
            sx={{
                '&::before': {
                    display: 'none'
                }
            }}
        >
            <List
                disablePadding
                sx={{ width: "100%" }}
            >
                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <MiscellaneousServices />
                    </ListItemIcon>
                    <ListItemText primary="OS" secondary={`#${item.so}`} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Today />
                    </ListItemIcon>
                    <ListItemText primary="Data de criação" secondary={moment(item.created_at).format("DD/MM/YYYY")} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Today />
                    </ListItemIcon>
                    <ListItemText primary="Data de agendamento" secondary={item.task ? moment(item.task.scheduled_at).format("DD/MM/YYYY HH:mm") : ""} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <EventAvailable />
                    </ListItemIcon>
                    <ListItemText primary="Data da resolução" secondary={item.resolution_at ? moment(item.resolution_at).format("DD/MM/YYYY HH:mm") : "N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Person2 />
                    </ListItemIcon>
                    <ListItemText primary="Assinante" secondary={item.signature_owner || "N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Person2 />
                    </ListItemIcon>
                    <ListItemText primary="Assinatura" secondary={item.signature ? "" : "N/A"} />
                </ListItem>

                {
                    item.signature && (
                        <Stack ml={7} mb={1}>
                            <img
                                alt="Assinatura Responsável"
                                style={{
                                    width: 250,
                                    objectFit: "contain",
                                    border: "1px solid #ddd",
                                    margin: "0 3px",
                                    cursor: "pointer",
                                    height: 150,
                                    padding: 0,
                                    borderRadius: 10,
                                }}
                                src={`data:image/jpeg;base64,${item.signature}`}
                            />
                        </Stack>
                    )
                }

                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Schedule />
                    </ListItemIcon>
                    <ListItemText primary="Descrição" secondary={item.description || "N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Schedule />
                    </ListItemIcon>
                    <ListItemText primary="Observações Internas" secondary={item.internal_observations || "N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Person />
                    </ListItemIcon>
                    <ListItemText primary="Nota Fiscal" secondary={item.invoice || "N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Business />
                    </ListItemIcon>
                    <ListItemText primary="Status" secondary={item.status_service_order && item.status_service_order.name} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <MiscellaneousServices />
                    </ListItemIcon>
                    <ListItemText primary="Status Máquina" secondary={item.status_equipment ? item.status_equipment.name : "N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <CoffeeMaker />
                    </ListItemIcon>
                    <ListItemText primary="Tipo de Serviço" secondary={item.task ? item.task.task_type.name : "N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <LocationOn />
                    </ListItemIcon>
                    <ListItemText primary="Responsável" secondary={item.responsible_user ? item.responsible_user.profile.name : ""} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <LocationOn />
                    </ListItemIcon>
                    <ListItemText primary="Unidade" secondary={item.unit ? item.unit.name : "N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <LocationOn />
                    </ListItemIcon>
                    <ListItemText primary="Endereço" secondary={item.unit ? item.unit.address : "N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <LocationOn />
                    </ListItemIcon>
                    <ListItemText primary="Técnicos Responsável" secondary={getTecsList()} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <ReportProblem />
                    </ListItemIcon>
                    <ListItemText primary="Observações da resolução" secondary={item.resolution_note || "N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <MiscellaneousServices />
                    </ListItemIcon>
                    <ListItemText primary="Família" secondary={getFamilyName()} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <MiscellaneousServices />
                    </ListItemIcon>
                    <ListItemText primary="Produto" secondary={getProductName()} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <MiscellaneousServices />
                    </ListItemIcon>
                    <ListItemText primary="Modelo" secondary={getModelName()} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <FmdBad />
                    </ListItemIcon>
                    <ListItemText primary="Problema" secondary={item.product_model_issue ? item.product_model_issue.name : "N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <EmojiObjects />
                    </ListItemIcon>
                    <ListItemText primary="Solução" secondary={item.product_model_solution ? item.product_model_solution.name : "N/A"} />
                </ListItem>
                <Divider component="li"/>
                    

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <MiscellaneousServices />
                    </ListItemIcon>
                    <ListItemText primary="Peças" secondary={getPartsList()} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Photo />
                    </ListItemIcon>
                    
                    <Stack>
                        <ListItemText
                            primary="Fotos do equipamento"
                            secondary={item.attachments && item.attachments.length > 0 ? "" : "N/A"}
                        />

                        {
                            (item.attachments && item.attachments.length > 0) && (
                                <>
                                    {
                                        Object.keys(stagesImages).map(key => (
                                            <>
                                                <ListItemText
                                                    primary={labels[key]}
                                                    secondary={stagesImages[key].length > 0 ? "" : "N/A"}
                                                    secondaryTypographyProps={{
                                                        ml: 2
                                                    }}
                                                />
                                                <Stack flexDirection="row" ml={2}>
                                                    {stagesImages[key].map(image => (
                                                        <a
                                                            href={`https://storage.googleapis.com/focalweb/attachments/${image.name}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {
                                                                image.type.includes("image") && (
                                                                    <img
                                                                        alt="Imagem Técnico"
                                                                        style={{
                                                                            width: 120,
                                                                            objectFit: "contain",
                                                                            border: "1px solid #ddd",
                                                                            margin: "0 3px",
                                                                            cursor: "pointer",
                                                                            height: 110,
                                                                            padding: 0,
                                                                            borderRadius: 10,
                                                                        }}
                                                                        src={`https://storage.googleapis.com/focalweb/attachments/${image.name}`}
                                                                    />
                                                                )
                                                            }

                                                            {
                                                                image.type.includes("video") && (
                                                                    <video
                                                                        alt="Vídeo Técnico"
                                                                        style={{
                                                                            width: 140,
                                                                            objectFit: "contain",
                                                                            border: "1px solid #ddd",
                                                                            margin: "0 3px",
                                                                            cursor: "pointer",
                                                                            height: 110,
                                                                            padding: 0,
                                                                            borderRadius: 10,
                                                                        }}
                                                                        src={`https://storage.googleapis.com/focalweb/attachments/${image.name}`}
                                                                    />
                                                                )
                                                            }
                                                        </a>
                                                        ))
                                                    }
                                                </Stack>
                                            </>
                                        ))
                                    }
                                </>
                            )
                        }
                    </Stack>
                </ListItem>
                <Divider component="li"/>

                {/* <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Notes />
                    </ListItemIcon>
                    <ListItemText primary="Observação" secondary={"N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Person />
                    </ListItemIcon>
                    <ListItemText primary="Solicitante" secondary={"BackOffice"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Engineering />
                    </ListItemIcon>
                    <ListItemText primary="Técnico responsável" secondary={"Francisco Silva"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Notes />
                    </ListItemIcon>
                    <ListItemText primary="Observações do técnico" secondary={"N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <TipsAndUpdates />
                    </ListItemIcon>
                    <ListItemText primary="Diagnóstico de problemas" secondary={"N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <SettingsSuggest />
                    </ListItemIcon>
                    <ListItemText primary="Diagnóstico de peças" secondary={"N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Photo />
                    </ListItemIcon>
                    
                    <Stack>
                        <ListItemText
                            primary="Fotos do equipamento"
                            secondary={"N/A"}
                        />
                    </Stack>
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <CoffeeMaker />
                    </ListItemIcon>
                    <ListItemText primary="Funcionamento da máquina" secondary={"N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Build />
                    </ListItemIcon>
                    <ListItemText primary="Status" secondary={ticketsStatus[1]} />
                </ListItem> */}
            </List>
        </TimelineItem>
    )
};

export default ScheduleItem;
