import {
  asyncFilterActions,
  asyncPartsActions,
  asyncProductModelActions,
} from "app/redux/reducers";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "./style";
import { Stack } from "@mui/material";
import DynamicViewGenerator from "app/components/DynamicViewGenerator/DynamicViewGenerator";
import { CLEAN_PARTS_STORE } from "app/redux/modules/Products/parts/constants";
import { DATA_TYPE_CHIP, DATA_TYPE_DATE, DATA_TYPE_ID, DATA_TYPE_STRING } from "app/components/DynamicViewGenerator/constants";
import { CLEAN_PRODUCT_MODEL_STORE } from "app/redux/modules/Products/productModel/constants";
import * as Yup from "yup";

const Parts = () => {
  const dispatch = useDispatch();
  const { parts, productModels, filterProductModel, auth } = useSelector((state) => state);
  const [newSelectOptions, setNewSelectOptions] = React.useState([]);
  const [categoryFilter, setCategoryFilter] = React.useState([]);

  const startPage = async () => {
     dispatch(asyncPartsActions.getParts("asc", "id"));
     dispatch(asyncProductModelActions.getProductModels("asc", "name",1,1,"",true));
     dispatch(asyncFilterActions.getProductModelsFilter())

  }

  useEffect(() => {
    startPage();
    return () => {
      dispatch({ type: CLEAN_PARTS_STORE });
      dispatch({ type: CLEAN_PRODUCT_MODEL_STORE });

    }
  }, [dispatch]);
 
  useEffect(() => {
    if (filterProductModel.data) {
      setCategoryFilter(
        filterProductModel.data.data && filterProductModel.data.data.map(({ id, name }) => ({ id, name }))
      );
    }
  }, [filterProductModel]);


  useEffect(() => {
    if (productModels.data) {
      setNewSelectOptions(
        productModels.data.data && productModels.data.data.map(({ id, name }) => ({ id, name }))
      );
    }
  }, [productModels]);


  const dataLabels = [
    {
      id: "Id",
      name: "Nome",
      code: "Código",
      product_model_id: "Modelo do Produto",
      created_at: "Data criação",
      status: "Status",
    },
  ];

  const dataType = [
    {
      id: DATA_TYPE_ID,
      name: DATA_TYPE_STRING,
      code: DATA_TYPE_STRING,
      status: DATA_TYPE_CHIP,
      created_at: DATA_TYPE_DATE,
      product_model_id: {
        parent: "product_model",
        name: "name",
      },
    },
  ];

  const formFieldsType = [
    {
      type: "text",
      value: "name",
      label: "Nome",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: "text",
      value: "code",
      label: "Code",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },    
    {
      type: "select",
      value: "product_model_id",
      label: "Modelo do Produto",
      options: newSelectOptions,
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: "status",
      value: "status",
      label: "Status",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
  ];

  const pageConfig = {
    title: "Peças",
    newText: "Nova peça",
    searchText: "Pesquisar",
    data: parts.data,
    loading: parts.loading,
    category: categoryFilter,
    actions: {
      delete: asyncPartsActions.deleteParts,
      edit: asyncPartsActions.editParts,
      get: asyncPartsActions.getParts,
      search: asyncPartsActions.getParts,
      create: asyncPartsActions.createParts,
      clean: { type: CLEAN_PARTS_STORE },
    },
    createDataType: formFieldsType,
  };

  return (
    <Content>
      {
        <Stack style={{ marginTop: 10 }}>
          <DynamicViewGenerator
            pageConfig={pageConfig}
            route={"modelos"}
            type={dataType}
            labels={dataLabels}
          />
        </Stack>
      }
    </Content>
  );
};

export default Parts;
