import {
  asyncFilterActions,
  asyncTicketsActions,
  asyncProductModelActions,
  asyncTicketsCategoriesActions,
} from "app/redux/reducers";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "./style";
import { Stack } from "@mui/material";
import DynamicViewGenerator from "app/components/DynamicViewGenerator/DynamicViewGenerator";
import { CLEAN_PARTS_STORE } from "app/redux/modules/Products/parts/constants";
import { DATA_TYPE_CHIP, DATA_TYPE_DATE, DATA_TYPE_ID, DATA_TYPE_STRING } from "app/components/DynamicViewGenerator/constants";
import { CLEAN_PRODUCT_MODEL_STORE } from "app/redux/modules/Products/productModel/constants";
import { CLEAN_TICKETS_STORE } from "app/redux/modules/Tickets/constants";
import { history } from "app/redux/store";
import { useParams } from "react-router-dom";
import { setStatus } from "app/redux/modules/Search/actions";

const TicketsCompany = () => {
  const dispatch = useDispatch();
  const { parts, productModels, filterProductModel, tickets, ticketsCategories, productFamilies } = useSelector((state) => state);
  const [newSelectOptions, setNewSelectOptions] = React.useState([]);
  const [categoryFilter, setCategoryFilter] = React.useState([]);
  const [newSelectFamilyOptions, setNewSelectFamilyOptions] = React.useState([]);
  const { id } = useParams();

  const startPage = async () => {
     dispatch(asyncTicketsActions.get('','','','','','',id));
     dispatch(asyncTicketsCategoriesActions.get());
     dispatch(setStatus(0))
  }

  useEffect(() => {
    startPage();
    return () => {
      dispatch({ type: CLEAN_TICKETS_STORE });
    }
  }, []);


  useEffect(() => {
    if (ticketsCategories.data) {
      setCategoryFilter(
        ticketsCategories.data && ticketsCategories.data.data.map(({ id, name }) => ({ id, name }))
      );
    }
  }, [ticketsCategories]);

  useEffect(() => {
    if (productFamilies.data) {
      setNewSelectFamilyOptions(
        productFamilies.data && productFamilies.data.data.map(({ id, name }) => ({ id, name }))
      );
    }
  }, []);

  const dataLabels = [
    {
      id: "Id",
      title: "Assunto",
      responsible_user: "Solicitante",
      category: "Categoria",
      channel: "Canal",
      updated_at: "Ultima Atualização",
      created_at: "Data de Criação",
      status: "Status",
    },
  ];

  const dataType = [
    {
      id: DATA_TYPE_ID,
      name: DATA_TYPE_STRING,
      code: DATA_TYPE_STRING,
      status: DATA_TYPE_CHIP,
      created_at: DATA_TYPE_DATE,
      product_model_id: {
        parent: "product_model",
        name: "name",
      },
      id: DATA_TYPE_ID,
      title: DATA_TYPE_STRING,
      responsible_user: {
        parent: "responsible_user",
        child: "profile",
        name: "name",
        },      
      category: {
        parent: "category",
        name: "name",
      },
      channel: {
        parent: "channel",
        name: "name",
      },
      updated_at: DATA_TYPE_DATE,
      created_at: DATA_TYPE_DATE,
      status: {
        parent: "status",
        name: "id",
      },
    },
  ];

  const status = [
  {
    id: 0,
    name: "Todos",
  },  
  {
    id: 1,
    name: "Aberto",
  },
  {
    id: 2,
    name: "Pendente",
  },
  {
    id: 3,
    name: "Resolvido",
  },
  {
    id: 4,
    name: "Fechado",
  },
  {
    id: 5,
    name: "Suspenso",
  }]

  const reorder = [{
    id: 'id',
    name: 'Id do ticket'
    },{ 
        id: 'title',
        name: 'Assunto'
    },{
        id: 'status_name',
        name: 'Status'
        
    },
    {
        id: 'channel_name',
        name: 'Canal'
    },
    {
        id: 'created_at',
        name: 'Data de criação'
    },
    {
        id: 'updated_at',
        name: 'Data de atualização'
    }]

  const pageConfig = {
    title: "Tickets de Suporte",
    newText: "Novo Ticket",
    searchText: "Pesquisar",
    data: tickets.data,
    loading: tickets.loading,
    category: categoryFilter,
    actions: {      
      get: asyncTicketsActions.get,
      search: asyncTicketsActions.get,
      create: asyncTicketsActions.create,
      clean: { type: CLEAN_TICKETS_STORE },
      getSingle: asyncTicketsActions.getSingle,
    },
    status: status,
    detailsPage: "/tickets/responder/",
    newPage: "/tickets/novo",
    company: id,
    user: '',
    reorder: reorder,
    familySearch: true,    
    familyData: newSelectFamilyOptions,
  };

  return (
    <Content>
      {
        <Stack style={{ marginTop: 10 }}>
          <DynamicViewGenerator
            pageConfig={pageConfig}
            route={"modelos"}
            type={dataType}
            labels={dataLabels}
          />
        </Stack>
      }
    </Content>
  );
};

export default TicketsCompany;
