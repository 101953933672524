import React from 'react';
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";
import {ASSET_KREBS} from "../../utils/constants/paths";

const Logo = ({mini, mode, sx}) => {
    return (
        <Div sx={{display: "inline-flex", ...sx}}>
            <Link href={'/home'}>
                <img
                    src={`${ASSET_KREBS}/logo-crop.png`}
                    alt="Krebs Logo"
                    width={120}
                />
                {/* {
                    !mini ?
                        <img
                            src={ mode === "light" ? `${ASSET_KREBS}/logo-crop.png` : `${ASSET_KREBS}/logo-crop.png`}
                            alt="Jumbo React"
                            width={120}
                        />
                        :
                        <img src={mode === "light" ? `${ASSET_KREBS}/logo-crop.png` : `${ASSET_KREBS}/logo-crop.png`} alt="Jumbo React" />
                } */}
            </Link>
        </Div>
    );
};

Logo.defaultProps = {
    mode: "light"
};

export default Logo;
