import React from 'react';
import ProjectItem from "./ProjectItem";
import {
    Typography,
} from "@mui/material";

const ProjectsList = ({ data }) => {
    /* const data = [
        {
            os: 8911287,
            "Máquina": "10711087",
            "Tempo Total": "0:08:45",
            "Técnico": "Francisco Eduardo Pereira Sousa Silva",
            "Abertura": "21/07/2023",
            "Solicitante": "Backoffice",
            "Ocorrência": "Outro"
        },
        {
            os: 8911287,
            "Máquina": "10711087",
            "Tempo Total": "0:08:45",
            "Técnico": "Francisco Eduardo Pereira Sousa Silva",
            "Abertura": "21/07/2023",
            "Solicitante": "Backoffice",
            "Ocorrência": "Outro"
        },
        {
            os: 8911287,
            "Máquina": "10711087",
            "Tempo Total": "0:08:45",
            "Técnico": "Francisco Eduardo Pereira Sousa Silva",
            "Abertura": "21/07/2023",
            "Solicitante": "Backoffice",
            "Ocorrência": "Outro"
        },
        {
            os: 8911287,
            "Máquina": "10711087",
            "Tempo Total": "0:08:45",
            "Técnico": "Francisco Eduardo Pereira Sousa Silva",
            "Abertura": "21/07/2023",
            "Solicitante": "Backoffice",
            "Ocorrência": "Outro"
        },
        {
            os: 8911287,
            "Máquina": "10711087",
            "Tempo Total": "0:08:45",
            "Técnico": "Francisco Eduardo Pereira Sousa Silva",
            "Abertura": "21/07/2023",
            "Solicitante": "Backoffice",
            "Ocorrência": "Outro"
        },
        {
            os: 8911287,
            "Máquina": "10711087",
            "Tempo Total": "0:08:45",
            "Técnico": "Francisco Eduardo Pereira Sousa Silva",
            "Abertura": "21/07/2023",
            "Solicitante": "Backoffice",
            "Ocorrência": "Outro"
        },
        {
            os: 8911287,
            "Máquina": "10711087",
            "Tempo Total": "0:08:45",
            "Técnico": "Francisco Eduardo Pereira Sousa Silva",
            "Abertura": "21/07/2023",
            "Solicitante": "Backoffice",
            "Ocorrência": "Outro"
        },
        {
            os: 8911287,
            "Máquina": "10711087",
            "Tempo Total": "0:08:45",
            "Técnico": "Francisco Eduardo Pereira Sousa Silva",
            "Abertura": "21/07/2023",
            "Solicitante": "Backoffice",
            "Ocorrência": "Outro"
        },
        {
            os: 8911287,
            "Máquina": "10711087",
            "Tempo Total": "0:08:45",
            "Técnico": "Francisco Eduardo Pereira Sousa Silva",
            "Abertura": "21/07/2023",
            "Solicitante": "Backoffice",
            "Ocorrência": "Outro"
        },
        {
            os: 8911287,
            "Máquina": "10711087",
            "Tempo Total": "0:08:45",
            "Técnico": "Francisco Eduardo Pereira Sousa Silva",
            "Abertura": "21/07/2023",
            "Solicitante": "Backoffice",
            "Ocorrência": "Outro"
        },
        {
            os: 8911287,
            "Máquina": "10711087",
            "Tempo Total": "0:08:45",
            "Técnico": "Francisco Eduardo Pereira Sousa Silva",
            "Abertura": "21/07/2023",
            "Solicitante": "Backoffice",
            "Ocorrência": "Outro"
        },
        {
            os: 8911287,
            "Máquina": "10711087",
            "Tempo Total": "0:08:45",
            "Técnico": "Francisco Eduardo Pereira Sousa Silva",
            "Abertura": "21/07/2023",
            "Solicitante": "Backoffice",
            "Ocorrência": "Outro"
        },
        {
            os: 8911287,
            "Máquina": "10711087",
            "Tempo Total": "0:08:45",
            "Técnico": "Francisco Eduardo Pereira Sousa Silva",
            "Abertura": "21/07/2023",
            "Solicitante": "Backoffice",
            "Ocorrência": "Outro"
        },
        {
            os: 8911287,
            "Máquina": "10711087",
            "Tempo Total": "0:08:45",
            "Técnico": "Francisco Eduardo Pereira Sousa Silva",
            "Abertura": "21/07/2023",
            "Solicitante": "Backoffice",
            "Ocorrência": "Outro"
        },
    ]; */

    return (
        <React.Fragment>
            {
                data && data.length > 0 ? (
                    <>
                        {
                            data.map((project, key) => (
                                <ProjectItem
                                    key={key}
                                    item={project}
                                />
                            ))
                        }
                    </>
                ) : (
                    <Typography variant={'h4'} mb={3}>Nenhuma ordem de serviço para listar</Typography>
                )
            }
        </React.Fragment>
    );
};

export default ProjectsList;
