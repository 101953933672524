export const SET_SEARCH = "SET_SEARCH";
export const CLEAN_SEARCH = "CLEAN_SEARCH";
export const SET_SEARCH_ORDER = "SET_SEARCH_ORDER";
export const SET_SEARCH_STATUS = "SET_SEARCH_STATUS";
export const SET_SEARCH_CATEGORY = "SET_SEARCH_CATEGORY";
export const SET_SEARCH_ORDER_BY = "SET_SEARCH_ORDER_BY";
export const SET_SEARCH_PAGE = "SET_SEARCH_PAGE";
export const SET_RESPONSE_TICKET = "SET_RESPONSE_TICKET";
export const SET_PRODUCT_FAMILY_ID = "SET_FAMILY_ID";
export const SET_COMPANY_TYPE_ID = "SET_COMPANY_TYPE_ID";
export const SET_SEARCH_CITY = "SET_SEARCH_CITY";
export const SET_SEARCH_UF = "SET_SEARCH_UF";