import React, { useEffect } from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Accordion, Card, Chip, Icon, IconButton, Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { history } from "app/redux/store";
import { appTheme } from "app/themes/app/theme";
import moment from "moment";
import { Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SimpleModalEditor from "./SimpleModalEditor";
import { object } from "prop-types";
import ChipStatus from "../ChipStatus";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { renderAvatar } from "app/utils/appHelpers";

const ListItems = ({ item, labels, type, route, actions, pageConfig  }) => {
  const navigate = useNavigate()
  const [expanded] = React.useState(false);
  const { auth } = useSelector((state) => state);

  const handleClick = async() => {
        if(pageConfig.detailsPage)
           navigate(`${pageConfig.detailsPage}${item.id}`, {state: {ticket: item}});

          

      };

  const [openSimpleEditor, setOpenSimpleEditor] = React.useState(false);
  const [editingItem, setEditingItem] = React.useState(null);

  const dispatch = useDispatch();

  const reorder = Object.keys(labels[0]);

  const itemDelete = (id) => {

    dispatch(actions.delete(id))

  }

  const handleOpenSimpleEditor = (item) => {
    setEditingItem(item);
    setOpenSimpleEditor(true);
    console.log(item)
  };

  const handleCloseSimpleEditor = () => {
    setOpenSimpleEditor(false);
    setEditingItem(null);

  };



  


  const formatType = (text, type) => {
    if (type === "date") {
      return moment(text).format("DD/MM/YYYY");
    }
    
    if (type === "string") {
      return text;
    }
    
    if (type === "chip") {
      return (
        <Chip
          label={text === "1" || text === 1 ? "Ativo" : "Desativado"}
          variant="filled"
          color={text === "1" || text === 1 ? "success" : "error"}
        />
      );
    }
    
    if (type === "id") {

      return (
        <Typography variant={"h6"} color={appTheme.palette.red100} mb={0}>
          {text}
        </Typography>
      );
    }
    
    if (type === "nested_name") {

      return (
        <Typography variant={"h6"} color={appTheme.palette.red100} mb={0}>
          {text.name}
        </Typography>
      );
    }
    
 
  };


  return (
    <>
         {pageConfig.createDataType && <SimpleModalEditor pageConfig={pageConfig} open={openSimpleEditor} handleClose={() => handleCloseSimpleEditor()} item={editingItem} />}

    <Card sx={{ mb: 1 }}  >

      <Accordion
        onChange={pageConfig.title === "Tickets de Suporte" && handleClick}
        expanded={expanded}
        square
        sx={{ borderRadius: 2 }}
      >

        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            px: 3,
            flex: 1,
            flexDirection: "row-reverse",
            minHeight: 90,

            "& .MuiAccordionSummary-content": {
              alignItems: "center",

              "&.Mui-expanded": {
                margin: "12px 0",
              },
            },
            ".MuiAccordionSummary-expandIconWrapper": {
              borderRadius: 1,
              border: 1,
              color: "text.secondary",
              borderColor: "divider",
              transform: "none",
              height: 28,
              width: 28,
              alignItems: "center",
              justifyContent: "center",
              mr: 1,

              "&.Mui-expanded": {
                transform: "none",
                color: "primary.main",
                borderColor: "primary.main",
              },

              "& svg": {
                fontSize: "1.25rem",
              },
            },
          }}
        >
          {reorder.map((key, index) =>

            index === 0 ? (

              <Div
                key={index}
                sx={{
                  mr: { xs: 0, md: 2 },
                  width: { xs: 30, md: 45 },
                  height: { xs: 30, md: 45 },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: appTheme.palette.red100,
                  borderRadius: "50px",
                  p: (theme) => theme.spacing(1, 2),
                  px: 1,
                  mb: { xs: 2, md: 0 },
                }}
              >
              {typeof type[0][key] === "object" ? 
                    (  
                      
                      type[0][key].name === 'avatar' &&
                          item[type[0][key].parent][type[0][key].name] !== null ?
                          <Div>
                          {/* <img style={{width: "55px", height: "55px", borderRadius: "50px"}} src={`${process.env.REACT_APP_PHOTO_PATH}${item[type[0][key].parent][type[0][key].name]}`} alt={item[type[0][key].parent][type[0][key].name]} /> */}
                          {renderAvatar(item[type[0][key].parent][type[0][key].name])}
                          </Div>
                          :
                          <Typography 
                          variant={"h6"}
                          color={"#fff"}
                          mb={0}
                          >           
                              {item[type[0][key].parent].name.charAt(0)}
                          </Typography>                  
                      ):(
                <Typography
                variant={"h6"}
                color={"#fff"}
                mb={0}
                >

                     
                  {item[key]}
                 
                </Typography>
                )}
              </Div>
            ) : (
              <Div
                key={index}

                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <Div
                  sx={{
                    flex: 1,
                    flexDirection: "column",
                    alignItems: { xs: "center", md: "flex-start" },
                  }}
                >
                  <Typography
                    fontSize={"12px"}
                    variant={"h6"}
                    color={"text.secondary"}
                    mb={0.25}
                  >

                    {labels[0][key]}
                  </Typography>
                  <Typography variant={"body1"}>
                    {typeof type[0][key] === "object" ? 
                    (
                      <>    
                     <Typography variant={"h6"} mb={0}>
                        {labels[0][key] === "Status" ? ( <ChipStatus status={item[type[0][key].parent][type[0][key].name]} />
                        ):(
                        <>
                          {item[type[0][key].parent] && item[type[0][key].parent][type[0][key].child] && item[type[0][key].parent][type[0][key].child][type[0][key].name]}
                          {item[type[0][key].parent]  && item[type[0][key].parent][type[0][key].name]}
                        </>)
                        
                        }  
                      
                     </Typography>
                     </>

                    ):(
                    <> 
                    {formatType(item[key], type[0][key])}
                    </>
                     )

                    }
                  </Typography>
                </Div>
              </Div>
            )
          )}              
          
          {actions.delete  &&

          <Div
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <Div
                  sx={{
                    flex: 1,
                    flexDirection: "column",
                    alignItems: { xs: "center", md: "flex-start" },
                  }}
                >
                  <Typography
                    fontSize={"12px"}
                    variant={"h6"}
                    color={"text.secondary"}
                    mb={0.25}
                  >

                    Ações
                  </Typography>
                  {auth.user.role[0].name == "ADMIN" && actions.delete &&
                  <IconButton onClick={() => itemDelete(item.id)}>
                    <Icon color={"error"}>delete</Icon>
                  </IconButton>
                  }
                  {actions.edit &&
                  <IconButton onClick={() => handleOpenSimpleEditor(item)}>
                    <Icon color={"warning"}>edit</Icon>
                  </IconButton>
                  }
                   {pageConfig.more &&
                  <IconButton onClick={() => handleClick()}>
                    <MoreHorizIcon />

                  </IconButton>
                  }
                  
                </Div>
              </Div>
 }

      </AccordionSummary>
      </Accordion>
    </Card>
    </>

  );
};

export default ListItems;
