import {
  GET_FILTER_PRODUCT_MODEL_REQUEST,
  GET_FILTER_PRODUCT_MODEL_SUCCESS,
  GET_FILTER_PRODUCT_MODEL_FAILURE,
  CLEAN_FILTER_PRODUCT_MODEL_STORE

} from "./constants";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function filterProductModel(state = initialState, action) {
  switch (action.type) {
    case GET_FILTER_PRODUCT_MODEL_REQUEST:  
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_FILTER_PRODUCT_MODEL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };   
    case GET_FILTER_PRODUCT_MODEL_FAILURE:    
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_FILTER_PRODUCT_MODEL_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
