import { uploadAttachments } from "../Attachments/actions";
import {
  REOPEN_SERVICE_ORDERS_REQUEST,
  REOPEN_SERVICE_ORDERS_FAILURE,
  REOPEN_SERVICE_ORDERS_SUCCESS,
  CANCEL_SERVICE_ORDERS_FAILURE,
  CANCEL_SERVICE_ORDERS_REQUEST,
  CANCEL_SERVICE_ORDERS_SUCCESS,
  GET_SERVICE_ORDERS_REQUEST,
  GET_SERVICE_ORDERS_FAILURE,
  GET_SERVICE_ORDERS_SUCCESS,
  CREATE_SERVICE_ORDERS_REQUEST,
  CREATE_SERVICE_ORDERS_SUCCESS,
  CREATE_SERVICE_ORDERS_FAILURE,  
} from "./constants";

import api from "app/services/config";

export const cancelServiceOrder =
  (id) => async (dispatch, getState) => {
    dispatch({ type: CANCEL_SERVICE_ORDERS_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.put(`/service-orders/${id}`, {
        status_service_order_id: 6,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: CANCEL_SERVICE_ORDERS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: CANCEL_SERVICE_ORDERS_FAILURE,
        payload: error.message,
      });
    }
  };

  export const getServiceOrder =
  (id) => async (dispatch, getState) => {
    dispatch({ type: GET_SERVICE_ORDERS_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.get(`/service-orders/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_SERVICE_ORDERS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SERVICE_ORDERS_FAILURE,
        payload: error.message,
      });
    }
  };

  export const createServiceOrder =
  (data, files) => async (dispatch, getState) => {
    dispatch({ type: CREATE_SERVICE_ORDERS_REQUEST});

    try {
      const { auth } = getState();
      const response = await api.post(`/service-orders/`, data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: CREATE_SERVICE_ORDERS_SUCCESS,
        payload: response.data,
      });

      console.log("UPLOAD > > ", files, response.data);

      dispatch(uploadAttachments(files, response.data.id));
    } catch (error) {
      dispatch({
        type: CREATE_SERVICE_ORDERS_FAILURE,
        payload: error.message,
      });
    }
  };

  export const reopenServiceOrder =
  (id, data, files) => async (dispatch, getState) => {
    dispatch({ type: REOPEN_SERVICE_ORDERS_REQUEST});

    data['previous_service_order_id'] = id;

    try {
      const { auth } = getState();
      const response = await api.post(`/service-orders/`, data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: REOPEN_SERVICE_ORDERS_SUCCESS,
        payload: response.data,
      });

      dispatch(uploadAttachments(files, response.data.id));
    } catch (error) {
      dispatch({
        type: REOPEN_SERVICE_ORDERS_FAILURE,
        payload: error.message,
      });
    }
  };

  export const editServiceOrder =
  (id, data, files) => async (dispatch, getState) => {
    dispatch({ type: CREATE_SERVICE_ORDERS_REQUEST});

    try {
      const { auth } = getState();
      const response = await api.post(`/service-orders/${id}`, data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: CREATE_SERVICE_ORDERS_SUCCESS,
        payload: response.data,
      });

      dispatch(uploadAttachments(files, id));
    } catch (error) {
      dispatch({
        type: CREATE_SERVICE_ORDERS_FAILURE,
        payload: error.message,
      });
    }
  };
