import {
  asyncProductFamiliesActions,
  asyncTicketsActions,
  asyncTicketsCategoriesActions,
} from "app/redux/reducers";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "./style";
import { Stack } from "@mui/material";
import DynamicViewGenerator from "app/components/DynamicViewGenerator/DynamicViewGenerator";
import { DATA_TYPE_CHIP, DATA_TYPE_DATE, DATA_TYPE_ID, DATA_TYPE_STRING } from "app/components/DynamicViewGenerator/constants";
import { CLEAN_TICKETS_STORE } from "app/redux/modules/Tickets/constants";
import { CLEAN_SEARCH } from "app/redux/modules/Search/constants";

const Tickets = ({ companyId }) => {
  const dispatch = useDispatch();
  const { tickets, ticketsCategories, productFamilies } = useSelector((state) => state);
  const [categoryFilter, setCategoryFilter] = React.useState([]);  
  const [newSelectFamilyOptions, setNewSelectFamilyOptions] = React.useState([]);

  const startPage = async () => {
    if(companyId) {
      dispatch(asyncTicketsActions.getByCompany(companyId));
    } else {
      dispatch(asyncTicketsActions.get());
      dispatch(asyncTicketsCategoriesActions.get());
      dispatch(asyncProductFamiliesActions.getProductFamilies());
    }
  }

  useEffect(() => {
    startPage();
    return () => {
      dispatch({ type: CLEAN_TICKETS_STORE });
      dispatch({ type: CLEAN_SEARCH })
    }
  }, []);


  useEffect(() => {
    if (ticketsCategories.data && !companyId) {
      setCategoryFilter(
        ticketsCategories.data && ticketsCategories?.data?.data?.map(({ id, name }) => ({ id, name }))
      );
    }    
  }, []); 

  useEffect(() => {
    if (productFamilies.data) {
      setNewSelectFamilyOptions(
        productFamilies.data && productFamilies?.data?.data?.map(({ id, name }) => ({ id, name }))
      );
    }
  }, []);



  const dataLabels = [
    {
      id: "Id",
      title: "Assunto",
      responsible_user: "Solicitante",
      category: "Categoria",
      channel: "Canal",
      product_family_id: "Família",
      updated_at: "Ultima Atualização",
      created_at: "Data de Criação",
      status: "Status",
    },
  ];

  const dataType = [
    {  
      id: DATA_TYPE_ID,
      title: DATA_TYPE_STRING,
      responsible_user: {
        parent: "responsible_user",
        child: "profile",
        name: "name",
        },      
      category: {
        parent: "category",
        name: "name",
      },
      channel: {
        parent: "channel",
        name: "name",
      },
      updated_at: DATA_TYPE_DATE,
      created_at: DATA_TYPE_DATE,
      status: {
        parent: "status",
        name: "id",
      },
      product_family_id: {
        parent: "product_family",
        name: "name",
      }, 
    },
  ];

  const reorder = [{
    id: 'id',
    name: 'Id do ticket'
    },{ 
        id: 'title',
        name: 'Assunto'
    },{
        id: 'status_name',
        name: 'Status'
        
    },
    {
        id: 'channel_name',
        name: 'Canal'
    },
    {
        id: 'created_at',
        name: 'Data de criação'
    },
    {
        id: 'updated_at',
        name: 'Data de atualização'
    }]
  const status = [
  {
    id: 0,
    name: "Todos",
  },
  {
    id: 1,
    name: "Aberto", 
  },
  {
    id: 2,
    name: "Pendente",
  },
  {
    id: 3,
    name: "Resolvido",
  },
  {
    id: 4,
    name: "Fechado",
  },
  {
    id: 5,
    name: "Suspenso",
  }]

  const pageConfig = {
    title: "Tickets de Suporte",
    newText: "Novo Ticket",
    searchText: "Pesquisar",
    hiddenSearch: companyId ? true : false,
    hiddenNewButton: companyId ? true : false,
    hiddenSort: companyId ? true : false,
    data: tickets.data,
    loading: tickets.loading,
    familySearch: true,    
    familyData: newSelectFamilyOptions,
    category: companyId ? null : categoryFilter,
    company: companyId,
    actions: {      
      get: asyncTicketsActions.get,
      search: asyncTicketsActions.get,
      create: asyncTicketsActions.create,
      clean: { type: CLEAN_TICKETS_STORE },
      getSingle: asyncTicketsActions.getSingle,
    },
    status: status,
    detailsPage: "/tickets/responder/",
    newPage: "/tickets/novo",
    reorder: reorder,
  };

  return (
    <Content>
      {
        <Stack style={{ marginTop: 10 }}>
          <DynamicViewGenerator
            pageConfig={pageConfig}
            route={"modelos"}
            type={dataType}
            labels={dataLabels}
          />
        </Stack>
      }
    </Content>
  );
};

export default Tickets;
