import React from "react";
import DvrIcon from '@mui/icons-material/Dvr';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const menus = [
    {
        label: 'Relatórios',
        type: "section",
        rolesAccess: ["*"],
        children: [
            {
                uri: "/home",
                label: 'Dashboard',
                type: "nav-item",
                icon: <DvrIcon sx={{fontSize: 20}}/>
            }],
    },
    // {
    
    //     label: 'Atendimento',
    //     type: "section",
    //     rolesAccess: ["*"],
    //     children: [
    //         {
    //             uri: "/os",
    //             label: 'Ordens de Serviço',
    //             type: "nav-item",
    //             icon: <DvrIcon sx={{fontSize: 20}}/>
    //         },
    //         {
    //             label: 'Ordens de Serviço',
    //             type: "collapsible",
    //             icon: <SupportAgentIcon sx={{fontSize: 20}}/>,
    //             rolesAccess: ["ADMIN", "SAC"],
    //             children: [
    //                 {
    //                     uri: "/tickets/todos",
    //                     label: 'Todos',
    //                     type: "nav-item",
    //                     // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
    //                 },
    //                 {
    //                     uri: "/tickets/aberto",
    //                     label: 'Aberto',
    //                     type: "nav-item",
    //                     // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
    //                 },
    //                 {
    //                     uri: "/tickets/pendente",
    //                     label: 'Pendente',
    //                     type: "nav-item",
    //                     // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
    //                 },
    //                 {
    //                     uri: "/tickets/resolvido",
    //                     label: 'Resolvido',
    //                     type: "nav-item",
    //                     // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
    //                 },
    //                 {
    //                     uri: "/tickets/fechado",
    //                     label: 'Fechado',
    //                     type: "nav-item",
    //                     // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
    //                 },
    //                 {
    //                     uri: "/tickets/suspenso",
    //                     label: 'Suspenso',
    //                     type: "nav-item",
    //                     // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
    //                 },
    //             ],
    //         },
    //         {
    //                     uri: "/agenda",
    //                     label: 'Agenda',
    //                     type: "nav-item",
    //                     icon: <CalendarTodayIcon sx={{fontSize: 20}}/>
    //         },   
    //     ],

    
    // },
    {
        label: 'Configurações',
        type: "section",
        rolesAccess: ["ADMIN"],
        children: [
            {
                label: 'Usuários',
                type: "collapsible",
                rolesAccess: ["*"],
                icon: <PeopleAlt sx={{fontSize: 20}}/>,
                children: [
                    {
                        uri: "/usuarios/clientes",
                        label: 'Clientes',
                        type: "nav-item",
                    
                    },
                    {
                        uri: "/usuarios/tecnicos",
                        label: 'Técnicos',
                        type: "nav-item",
                    
                    },
                    {
                        uri: "/usuarios/administradores",
                        label: 'Administradores',
                        type: "nav-item",
                        rolesAccess: ["ADMIN", "SAC"],
                    
                    },
                    {
                        uri: "/usuarios/sac",
                        label: 'Sac',
                        type: "nav-item",
                        rolesAccess: ["ADMIN", "SAC"],
                    },
                    // {
                    //     uri: "/usuarios/revendas",
                    //     label: 'Revendas',
                    //     type: "nav-item",
                    //     rolesAccess: ["ADMIN", "SAC"],
                    // },
                    // {
                    //     uri: "/usuarios/somente-leitura",
                    //     label: 'Somente Leitura',
                    //     type: "nav-item",
                    //     rolesAccess: ["ADMIN", "SAC"],
                    // }
                ]
            },
            // {
            //     label: 'Empresas',
            //     type: "collapsible",
            //     rolesAccess: ["*"],
            //     icon: <LocationCityIcon sx={{fontSize: 20}}/>,
            //     children: [       
            //         {
            //             uri: "/empresas",
            //             label: 'Empresas',
            //             type: "nav-item",
                    
            //         },
            //         {
            //             uri: "/empresas/unidades",
            //             label: 'Unidades',
            //             type: "nav-item",
                    
            //         }            
            //     ]
            // },
            // {
            //     label: 'Produtos',
            //     type: "collapsible",
            //     rolesAccess: ["ADMIN", "SAC"],
            //     icon: <ProductionQuantityLimitsIcon sx={{fontSize: 20}}/>,
            //     children: [
            //         {
            //             uri: "/produtos/familias",
            //             label: 'Famílias',
            //             type: "nav-item",
                    
            //         },
            //         {
            //             uri: "/produtos/product",
            //             label: 'Produtos',
            //             type: "nav-item",
                    
            //         },
            //         {
            //             uri: "/produtos/modelos",
            //             label: 'Modelos',
            //             type: "nav-item",
                    
            //         },
            //         {
            //             uri: "/produtos/pecas",
            //             label: 'Peças',
            //             type: "nav-item",
                    
            //         },
            //         {
            //             uri: "/produtos/solucoes",
            //             label: 'Soluções',
            //             type: "nav-item",
                    
            //         },
            //         {
            //             uri: "/produtos/problemas",
            //             label: 'Problemas',
            //             type: "nav-item",
                    
            //         }
        
            //     ]
            // },
            // {
            //     label: 'Configurações',
            //     type: "collapsible",
            //     icon: <SettingsIcon sx={{fontSize: 20}}/>,
            //     children: [
            //         {
            //             uri: "/configuracoes/categorias-de-tickets",
            //             label: 'Categorias de Tickets',
            //             type: "nav-item",
                    
            //         },
            //         {
            //             uri: "/configuracoes/macros",
            //             label: 'Macros',
            //             type: "nav-item",
                    
            //         },
            //         {
            //             uri: "/configuracoes/tipos-de-problemas",
            //             label: 'Tipos de Problema',
            //             type: "nav-item",
                    
            //         },            
            //         {
            //             uri: "/configuracoes/categorias-de-resolucao",
            //             label: 'Categorias de Resolução',
            //             type: "nav-item",
                    
            //         },
            //         {
            //             uri: "/configuracoes/tipos-de-atividades",
            //             label: 'Tipos de Atividade',
            //             type: "nav-item",
                    
            //         }
            //     ]
            // },            
        ]
    }
];

export default menus;
