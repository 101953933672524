import {
  GET_UNITS_REQUEST,
  GET_UNITS_SUCCESS,
  GET_UNITS_FAILURE,

  CLEAN_UNITS_STORE,
  
  DELETE_UNITS_REQUEST,
  DELETE_UNITS_SUCCESS,
  DELETE_UNITS_FAILURE,

  POST_UNITS_REQUEST,
  POST_UNITS_FAILURE,
  POST_UNITS_SUCCESS,

  PUT_UNITS_REQUEST,
  PUT_UNITS_FAILURE,
  PUT_UNITS_SUCCESS,
  GET_UNITS_FROM_COMPANY_REQUEST,
  GET_UNITS_FROM_COMPANY_SUCCESS,
  GET_UNITS_FROM_COMPANY_FAILURE,
} from "./constants";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function units(state = initialState, action) {
  switch (action.type) {
    case GET_UNITS_REQUEST:
    case GET_UNITS_FROM_COMPANY_REQUEST:
    case DELETE_UNITS_REQUEST:
    case POST_UNITS_REQUEST:
    case PUT_UNITS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_UNITS_SUCCESS:
    case GET_UNITS_FROM_COMPANY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_UNITS_SUCCESS:
    case POST_UNITS_SUCCESS:
    case PUT_UNITS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_UNITS_FAILURE:
    case GET_UNITS_FROM_COMPANY_FAILURE:
    case POST_UNITS_FAILURE:
    case DELETE_UNITS_FAILURE:
    case PUT_UNITS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_UNITS_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
