import { asyncUploadPhotoActions } from "app/redux/reducers";
import {
  GET_RESELLER_SUCCESS,
  GET_RESELLER_FAILURE,
  GET_RESELLER_REQUEST,
  DELETE_RESELLER_FAILURE,
  DELETE_RESELLER_REQUEST,
  DELETE_RESELLER_SUCCESS,
  POST_RESELLER_REQUEST,
  POST_RESELLER_FAILURE,
  POST_RESELLER_SUCCESS,
  PUT_RESELLER_REQUEST,
  PUT_RESELLER_FAILURE,
  PUT_RESELLER_SUCCESS,
} from "./constants";
import api from "app/services/config";
import { SET_ALERT } from "../../Alerts/constants";

export const getResellers =
  (order, orderBy, status, page, searchKey, noPaginate, unit_id) => async (dispatch, getState) => {
    dispatch({ type: GET_RESELLER_REQUEST });

    try {
      const { auth, search } = getState();
      const response = await api.get(`/users`, {
        params: {
          orderBy: `${search.data.orderBy},${search.data.order}`,
          status: status ? status : search.data.status,
          page: page ? page : search.data.page,
          s: searchKey,
          noPaginate: noPaginate,
          role_id: 5,
          unit_id: unit_id
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_RESELLER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_RESELLER_FAILURE,
        payload: error.message,
      });
    }
  };

export const deleteResellers = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_RESELLER_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.delete(`/users/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    dispatch({
      type: DELETE_RESELLER_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Deletado com sucesso", variant: "success" }, 
    });
  } catch (error) {
    dispatch({
      type: DELETE_RESELLER_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao deletar", variant: "error" }, 
    });
  } finally {
    dispatch(getResellers("asc", "id"));
  }
};
export const createResellers =
  (data) => async (dispatch, getState) => {
    dispatch({ type: POST_RESELLER_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.post(
        `/users`,
        {
          name: data.name,
          last_name: data.last_name,                    
          cpf: data.cpf,
          email: data.email,
          password: data.password,
          phone: data.phone,
          role: "RESALE",
          company_id: data.company_id,
          unit_id: data.unit_id
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      if(data.avatar.path){
        await dispatch(asyncUploadPhotoActions.post(data.avatar, response.data.user.id));
      } 
      dispatch({
        type: POST_RESELLER_SUCCESS,
        payload: response.data,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Criado com sucesso", variant: "success" }, 
      });
    } catch (error) {
      dispatch({
        type: POST_RESELLER_FAILURE,
        payload: error.message,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Erro ao criar", variant: "error" }, 
      });

    } finally {
      dispatch(getResellers("asc", "id"));
    }
  };

  export const editResellers =
  (data, id) => async (dispatch, getState) => {
    dispatch({ type: PUT_RESELLER_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.put(
        `/users/${id}`,
        {
          name: data.name,
          last_name: data.last_name,                    
          cpf: data.cpf,
          email: data.email,
          password: data.password,
          phone: data.phone,
          role: "RESALE",
          company_id: data.company_id,
          unit_id: data.unit_id
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      if(data.avatar instanceof File){
        await dispatch(asyncUploadPhotoActions.post(data.avatar, id));
     }
      dispatch({
        type: PUT_RESELLER_SUCCESS,
        payload: response.data,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Editado com sucesso", variant: "success" }, 
      });
    } catch (error) {
      dispatch({
        type: PUT_RESELLER_FAILURE,
        payload: error.message,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Erro ao editar", variant: "error" }, 
      });
    } finally {
      dispatch(getResellers("asc", "id"));
    }
  };
