import { SET_ALERT } from "../../Alerts/constants";
import {
  GET_UNITS_SUCCESS,
  GET_UNITS_FAILURE,
  GET_UNITS_REQUEST,
  DELETE_UNITS_FAILURE,
  DELETE_UNITS_REQUEST,
  DELETE_UNITS_SUCCESS,
  POST_UNITS_REQUEST,
  POST_UNITS_FAILURE,
  POST_UNITS_SUCCESS,
  PUT_UNITS_REQUEST,
  PUT_UNITS_FAILURE,
  PUT_UNITS_SUCCESS,
  GET_UNITS_FROM_COMPANY_FAILURE,
  GET_UNITS_FROM_COMPANY_SUCCESS,
  GET_UNITS_FROM_COMPANY_REQUEST,
} from "./constants";
import api from "app/services/config";

export const getUnits =
  (order, orderBy, status, page, searchKey, noPaginate) => async (dispatch, getState) => {
    dispatch({ type: GET_UNITS_REQUEST });

    try {
      const { auth, search } = getState();

      const response = await api.get(`/units`, {
        params: {
          orderBy: `${search.data.orderBy},${search.data.order}`,
          status: search.data.status,
          page: search.data.page,
          s: searchKey,
          noPaginate: noPaginate
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_UNITS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_UNITS_FAILURE,
        payload: error.message,
      });
    }
  };

  
export const getUnitsFromCompany =
(id) => async (dispatch, getState) => {
  dispatch({ type: GET_UNITS_FROM_COMPANY_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.get(`/units`, {
      params: {
        orderBy: `name,desc`,
        status: 1,
        company_id: id,
        noPaginate: true
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    dispatch({
      type: GET_UNITS_FROM_COMPANY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_UNITS_FROM_COMPANY_FAILURE,
      payload: error.message,
    });
  }
};

export const deleteUnits = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_UNITS_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.delete(`/units/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    dispatch({
      type: DELETE_UNITS_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Deletado com sucesso", variant: "success" }, 
    });
  } catch (error) {
    dispatch({
      type: DELETE_UNITS_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao deletar", variant: "error" }, 
    });
  } finally {
    dispatch(getUnits("asc", "id"));
  }
};
export const createUnits =
  (data) => async (dispatch, getState) => {
    dispatch({ type: POST_UNITS_REQUEST });
    try {
      console.log('data em units', data)

      const { auth } = getState();
      const response = await api.post(
        `/units`,
        {
          name: data.name,
          description: data.description,
          classification: data.classification,
          address: data.address.formatted_address,
          address_add_info: data.address_add_info,
          longitude: data.address.geometry.location.lng,
          latitude: data.address.geometry.location.lat,
          company_id: data.company_id,
          status: data.status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      dispatch({
        type: POST_UNITS_SUCCESS,
        payload: response.data,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Criado com sucesso", variant: "success" }, 
      });
    } catch (error) {
      dispatch({
        type: POST_UNITS_FAILURE,
        payload: error.message,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Erro ao criar", variant: "error" }, 
      });
    } finally {
      dispatch(getUnits("asc", "id"));
    }
  };

  export const editUnits =
  (data, id) => async (dispatch, getState) => {
    dispatch({ type: PUT_UNITS_REQUEST });

    

    try {
      
      const { auth } = getState();
      const response = await api.put(
        `/units/${id}`,
        {
          name: data.name,
          description: data.description,
          classification: data.classification,
          company_id: data.company_id,
          status: data.status,
          address_add_info: data.address_add_info,
          address: data.address.formatted_address,
          longitude: data.address.geometry?.location?.lng,
          latitude: data.address.geometry?.location?.lat,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      dispatch({
        type: PUT_UNITS_SUCCESS,
        payload: response.data,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Editado com sucesso", variant: "success" }, 
      });
    } catch (error) {
      dispatch({
        type: PUT_UNITS_FAILURE,
        payload: error.message,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Erro ao editar", variant: "error" }, 
      });
    } finally {
      dispatch(getUnits());
    }
  };
