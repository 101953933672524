//UNITS Types

export const GET_UNITS_REQUEST = "GET_UNITS_REQUEST";
export const GET_UNITS_FAILURE = "GET_UNITS_FAILURE";
export const GET_UNITS_SUCCESS = "GET_UNITS_SUCCESS";

export const GET_UNITS_FROM_COMPANY_REQUEST = "GET_UNITS_FROM_COMPANY_REQUEST";
export const GET_UNITS_FROM_COMPANY_FAILURE = "GET_UNITS_FROM_COMPANY_FAILURE";
export const GET_UNITS_FROM_COMPANY_SUCCESS = "GET_UNITS_FROM_COMPANY_SUCCESS";

export const GET_SINGLE_UNITS_REQUEST = 'get-single-UNITS-request'
export const GET_SINGLE_UNITS_FAILURE = 'get-single-UNITS-failure';
export const GET_SINGLE_UNITS_SUCCESS = 'get-single-UNITS-success';

export const POST_UNITS_REQUEST = "POST_UNITS_REQUEST";
export const POST_UNITS_FAILURE = "post-UNITS-failure";
export const POST_UNITS_SUCCESS = "post-UNITS-success";

export const PUT_UNITS_REQUEST = "PUT_UNITS_REQUEST";
export const PUT_UNITS_FAILURE = "put-UNITS-failure";
export const PUT_UNITS_SUCCESS = "put-UNITS-success";

export const DELETE_UNITS_REQUEST = "DELETE_UNITS_REQUEST";
export const DELETE_UNITS_FAILURE = "delete-UNITS-failure";
export const DELETE_UNITS_SUCCESS = "delete-UNITS-success";



export const CLEAN_UNITS_STORE = "CLEAN_UNITS_STORE";