import JumboCardQuick from "@jumbo/components/JumboCardQuick/JumboCardQuick";
import JumboContent from "@jumbo/components/JumboContent/JumboContent";
import Div from "@jumbo/shared/Div/Div";
import {
    Autocomplete,
    Button,
    CardHeader,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DzPreviews from "./components/DzPreviews";
import { useFormik } from 'formik';
import * as Yup from "yup";
import api from "../../services/config";
import { useDispatch, useSelector } from "react-redux";
import Loading from "app/components/Loading";
import SimpleMap from "./components/SimpleMap";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from "moment";
import { useParams } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar, DatePicker, StaticDatePicker  } from "@mui/x-date-pickers";
import { cleanSearch, setCategory, setCompanyTypeId, setStatus } from "app/redux/modules/Search/actions";
import { getCompanies, getResales } from "app/redux/modules/Customers/Companies/actions";
import { CLEAN_COMPANIES_STORE } from "app/redux/modules/Customers/Companies/constants";
import { getCustomers } from "app/redux/modules/Users/Customers/actions";
import { CLEAN_CUSTOMERS_STORE } from "app/redux/modules/Users/Customers/constants";
import { getUnitsFromCompany } from "app/redux/modules/Customers/Units/actions";
import { getProduct } from "app/redux/modules/Products/product/actions";
import { getProductModels } from "app/redux/modules/Products/productModel/actions";
import { getIssues } from "app/redux/modules/Products/issues/actions";
import { getProductFamilies } from "app/redux/modules/Products/families/actions";
import { CLEAN_PRODUCT_FAMILIES_STORE } from "app/redux/modules/Products/families/constants";
import { CLEAN_ISSUES_STORE } from "app/redux/modules/Products/issues/constants";
import { CLEAN_PRODUCT_STORE } from "app/redux/modules/Products/product/constants";
import { CLEAN_PRODUCT_MODEL_STORE } from "app/redux/modules/Products/productModel/constants";
import { asyncServiceOrdersActions, asyncTaskTypesActions } from "app/redux/reducers";
import { CLEAN_UNITS_STORE } from "app/redux/modules/Customers/Units/constants";
import { CLEAN_SERVICE_ORDERS_STORE } from "app/redux/modules/ServiceOrders/constants";
import Lottie from "lottie-react";
import locationAnimation from "assets/animations/locationAnimation.json";
import dayjs from "dayjs";

export default function NovoChamado() {
    const dispatch = useDispatch();
    const {
        companies: {
            data,
            resales
        },
        units,
        customers,
        productFamilies,
        product,
        productModels,
        issues,
        taskTypes,
        serviceOrders
    } = useSelector(state => state);

    const { auth } = useSelector(state => state);

    const { id } = useParams();
    const [initalized, setInitialized] = useState(false);
    const [openInitCalendar, setOpenInitCalendar] = useState(false);
    const [openEndCalendar, setOpenEndCalendar] = useState(false);

    const [showImagePicker, setShowImagePicker] = useState(false);
    const [images, setImages] = useState([]);
    const [showVideoPicker, setShowVideoPicker] = useState(false);
    const [videos, setVideos] = useState([]);
    const [responsibles, setResponsibles] = useState([]);

    const [propertyLocation, setPropertyLocation] = useState(null);
    const [technicians, setTechnicians] = useState([]);
    const [productPartsIds, setProductPartsIds] = useState([]);
    const [loading, setLoading] = useState();
    const [showFreeTimes, setShowFreeTimes] = useState(false);
    const [freeTimes, setFreeTimes] = useState(["08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00"]);
    
    const [enableProducts, setEnableProducts] = useState(false);
    const [enableModels, setEnableModels] = useState(false);
    const [enableIssues, setEnableIssues] = useState(false);

    const validationSchema = Yup.object().shape({
        company: Yup.string().required("Campo obrigatório"),
        property: Yup.string().required("Campo obrigatório"),
        client: Yup.string().required("Campo obrigatório"),
        family: Yup.string().required("Campo obrigatório"),
        product: Yup.string().required("Campo obrigatório"),
        model: Yup.string().required("Campo obrigatório"),
        issue: Yup.string().required("Campo obrigatório"),
        description: Yup.string().required("Campo obrigatório"),
        internal_observations: Yup.string().required("Campo obrigatório"),
        invoice: Yup.string().optional(),
        service_type: Yup.string().required("Campo obrigatório"),
        scheduled_at: Yup.date().min(dayjs().startOf("day").subtract(1, "s"), "Data não permitida").nullable().required("Campo obrigatório").typeError('Data inválida'),
        time: Yup.string().required("Campo obrigatório"),
        end: Yup.date().min(Yup.ref("scheduled_at"), "Data não permitida").nullable().required("Campo obrigatório").typeError('Data inválida'),
        time_end: Yup.string().required("Campo obrigatório"),
        technicians_origin: Yup.string().required("Campo obrigatório"),
        resale: Yup.string().ensure().when('technicians_origin', {
            is: "revenda",
            then:Yup.string().required("Campo obrigatório"),
        }),
        responsibles: Yup.array().min(1, "Campo obrigatório"),
    });

    function clearStore() {
        dispatch(cleanSearch());
        dispatch({ type: CLEAN_COMPANIES_STORE });
        dispatch({ type: CLEAN_CUSTOMERS_STORE });
        dispatch({ type: CLEAN_PRODUCT_FAMILIES_STORE });
        dispatch({ type: CLEAN_ISSUES_STORE });
        dispatch({ type: CLEAN_PRODUCT_STORE });
        dispatch({ type: CLEAN_PRODUCT_MODEL_STORE });
        dispatch({ type: CLEAN_UNITS_STORE });
        dispatch({ type: CLEAN_ISSUES_STORE });
        dispatch({ type: CLEAN_SERVICE_ORDERS_STORE });
    }

    useEffect(() => {
        clearStore();
        dispatch(getResales());
        dispatch(asyncTaskTypesActions.get("", "", "", "", "", true));
        dispatch(getProductFamilies("", "", "", "", "", true));
        dispatch(setStatus(1));
        dispatch(setCompanyTypeId(1));
        dispatch(getCompanies("", "", "", "", "", true))

        if(id) {
            dispatch(asyncServiceOrdersActions.get(id))
        }

        return () => clearStore();
    }, [id]);

    useEffect(async () => {
        if(serviceOrders.data.id && !serviceOrders.loading) {
            setLoading(true);
            const so = serviceOrders.data;

            const fetchs = [
                dispatch(getCustomers("", "", "", "", so.responsible_user.profile.name, true, so.unit_id)),
                dispatch(getUnitsFromCompany(so.unit.company_id)),
                dispatch(setCategory(so.product_model.product.product_family_id)),
                dispatch(getProduct("", "", "", "", "", true)),
                dispatch(setCategory(so.product_model.product_id)),
                dispatch(getProductModels("", "", "", "", "", true, true)),
                dispatch(setCategory(so.product_model_id)),
                dispatch(getIssues("", "", "", "", "", true))
            ]

            await Promise.all(fetchs).then(async (res) => {

                await formik.setValues({
                    company: so.unit.company.id,
                    property: so.unit.id,
                    client: so.responsible_user.id,
                    family: so.product_model.product.product_family_id,
                    product: so.product_model.product_id,
                    model: so.product_model_id,
                    issue: so.product_model_issue_id,
                    description: so.description,
                    internal_observations: so.internal_observations,
                    invoice: so.invoice,
                    service_type: so.task.task_type_id,
                    scheduled_at: dayjs(),
                    end: dayjs(),
                    responsibles: [],
                })

                if(auth.user && auth.user.role[0].name == "RESALE") {
                    await formik.setFieldValue("technicians_origin", "revenda");
                    await formik.setFieldValue("resale", auth.user.profile.unit.company_id);
                } else
                    await formik.setFieldValue("technicians_origin", "krebsfer");

                setShow(true);
                setPreviewData({
                    company: so.unit.company.name,
                    property: so.unit.address,
                    client: `${so.responsible_user.profile.name} ${so.responsible_user.profile.last_name}`,
                    family: so.product_model.product.product_family.name,
                    product: so.product_model.product.name,
                    model: so.product_model.name,
                    issue: so.product_model_issue.name,
                    description: so.description,
                    internal_observations: so.internal_observations,
                    invoice: so.invoice,
                    service_type: so.task.task_type.name,
                    responsibles: "",
                    scheduled_at: [dayjs().format("DD/MM/YYYY"), ""],
                    endAt: [dayjs().format("DD/MM/YYYY"), ""]
                })

                setPropertyLocation({ lat: Number(so.unit.latitude), lng: Number(so.unit.longitude) });
                setEnableProducts(true);
                setEnableModels(true);
                setEnableIssues(true);
                setShowFreeTimes(true);
            })

            setLoading(false);
        }
    }, [serviceOrders.loading]);

    const [previewData, setPreviewData] = useState({
        company: "",
        property: "",
        client: "",
        family: "",
        product: "",
        model: "",
        issue: "",
        description: "",
        internal_observations: "",
        invoice: "",
        service_type: "",
        responsibles: "",
        scheduled_at: ["", ""],
        endAt: ["", ""]
    });   

    const [show, setShow] = useState(false);

    const formik = useFormik({
        initialValues: {
            company: "",
            property: "",
            client: "",
            family: "",
            model: "",
            issue: "",
            product: "",
            description: "",
            internal_observations: "",
            invoice: "",
            service_type: "",
            time: "",
            scheduled_at: null, 
            time_end: "",
            end: null,
            resale: "",
            technicians_origin: "",
            responsibles: [],
        },
        validationSchema,
        onSubmit: handleSubmit,
    });
    
    useEffect(() => {
        if(auth.user && auth.user.role[0].name == "RESALE") {
            formik.setFieldValue("technicians_origin", "revenda");
            formik.setFieldValue("resale", auth.user.profile.unit.company_id);
        }
    }, []);

    useEffect(() => {
        if(auth.user.role[0].name == "RESALE" && !disableTecsSearch())
            searchResponsibles("");
    }, [previewData]);

    useEffect(() => {
        if(previewData.scheduled_at[0] != "" && previewData.scheduled_at[1] != "" &&
            previewData.endAt[0] != "" && previewData.endAt[1] != ""
        )
            searchResponsibles("");
    }, [previewData.scheduled_at, previewData.endAt]);

    async function searchResponsibles(s) {
        try {
            const startTask = formik.values["scheduled_at"].hour(formik.values["time"].split(":")[0]).minute(0).add(1, "minute").format("YYYY-MM-DD HH:mm:00");
            const endTask = formik.values["end"].hour(formik.values["time_end"].split(":")[0]).minute(0).add(1, "minute").format("YYYY-MM-DD HH:mm:00")
            
            let companyId = formik.values.technicians_origin == "krebsfer" ? 5 : formik.values.resale;

            if(auth.user.role[0].name == "RESALE") {
                companyId = auth.user.profile.unit.company_id
            }

            const {data} = await api.get(
                `users/tecs/free?s=${s}`,
                {
                    params: {
                        startTask,
                        endTask,
                        role_id: 3,
                        company_id: companyId
                    },
                    headers: { Authorization: `Bearer ${auth.token}` }
                }
            );

            setResponsibles(data.data);
        } catch (error) {
            console.log("ERROR > > ", error);
        }
    }

    useEffect(() => {
        if(formik.values.technicians_origin == "krebsfer" || formik.values.resale) {
            if(formik.values.technicians_origin == "krebsfer") {
                formik.setFieldValue("resale", null);
            }
            setTechnicians([]);
            formik.setFieldValue("responsibles", []);
            searchResponsibles("");
        }
    }, [formik.values.technicians_origin, formik.values.resale]);

    async function getFreeTimesUser() {
        let init = 8;
        if(previewData['scheduled_at'][0] == previewData['endAt'][0]) {
            init = Number(previewData['scheduled_at'][1].split(":")[0]) + 1;
        }

        if(init < 8) return;

        const free = [];
        
        for(init; init <= 22; init++) {
            free.push(`${init < 10 ? "0"+init : init}:00`);
        }

        setFreeTimes(free);
        setShowFreeTimes(true);
    }

    useEffect(() => {
        getFreeTimesUser();
    }, [previewData]);

    async function handleSubmit(dt) {
        setLoading(true);

        const files = [];

        for (let i = 0; i < images.length; i++) {
            files.push({
                file: images[i],
                description: `Imagem atendimento ${i + 1}`
            });
        }

        for (let i = 0; i < videos.length; i++) {
            files.push({
                file: videos[i],
                description: `Video atendimento ${i + 1}`
            });
        }

        const serviceData = {
            description: dt.description,
            invoice: dt.invoice,
            status: "Aberto",
            service_type: dt.service_type,
            responsible_user_id: dt.client,
            latitude: propertyLocation.lat,
            longitude: propertyLocation.lng,
            internal_observations: dt.internal_observations,
            orders_type_id: 1,
            user_id: auth.user?.id,
            technicians_ids: dt.responsibles,
            unit_id: dt.property,
            product_parts_ids: productPartsIds,
            product_model_id: dt.model,
            product_model_issue_id: dt.issue,
            scheduled_at: dt.scheduled_at.hour(dt.time.split(":")[0]).minute(0).format("YYYY-MM-DD HH:mm:00"),
            end_at: dt.end.hour(dt.time_end.split(":")[0]).minute(0).format("YYYY-MM-DD HH:mm:00"), 
        }

        try {
            if(id && window.location.pathname.includes("reabrir")) {
                dispatch(asyncServiceOrdersActions.reopen(id, serviceData, files));
            } else if(id && !window.location.pathname.includes("reabrir")) {
                delete serviceData.status;
                dispatch(asyncServiceOrdersActions.edit(id, serviceData, files));
            } else {
                dispatch(asyncServiceOrdersActions.create(serviceData, files));
            }
        } catch (error) {
            console.log("console.log('AQUI SUBMIT') ", error);
        }

        setLoading(false);
    }

    React.useEffect(() => {
        if(!showImagePicker) {
            setImages([]);
        }

        if(!showVideoPicker) {
            setVideos([]);
        }
    }, [showImagePicker, showVideoPicker]);

    const disableTecsSearch = () => {
        return previewData.scheduled_at[0] == "" || previewData.scheduled_at[1] == "" ||
            previewData.endAt[0] == "" || previewData.endAt[1] == "" 
    }

    return (
        <Div>
            <Stack>
                <Typography variant={'h2'} mb={3}>Criar nova Ordem de Serviço</Typography>
            </Stack>

            {
                (serviceOrders.loading || loading) && <Loading />
            }

            <JumboCardQuick>
                <JumboContent>
                    <Stack direction="row" flex={1}>
                        <form
                            autoComplete="off"
                            style={{ display: "flex", flex: 1 }}
                            onSubmit={formik.handleSubmit}
                        >
                            <Div sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <CardHeader
                                    title="Cliente"
                                    sx={{ mb: -1, ml: -2 }}
                                />

                                <Divider sx={{ mb: 2 }} />                                

                                <FormControl sx={{ mb: 1 }}>
                                    <Autocomplete
                                        disablePortal
                                        options={data.data}
                                        getOptionLabel={company => company.name}
                                        fullWidth
                                        value={formik.values.company && data.data && data.data.length > 0
                                            ? data.data.filter(co => co.id == formik.values.company)[0] 
                                            : null}
                                        noOptionsText="Busque por uma empresa..."
                                        onChange={(event, newValue, reason) => {
                                            if(reason == "clear") {
                                                dispatch({ type: CLEAN_UNITS_STORE });
                                                dispatch({ type: CLEAN_CUSTOMERS_STORE });
                                                formik.setFieldValue("company", "");
                                                formik.setFieldValue("property", "");
                                                formik.setFieldValue("client", "");
                                                setPropertyLocation(null);
                                                setPreviewData({
                                                    ...previewData,
                                                    company: "",
                                                    property: "",
                                                    client: "",
                                                })
                                            } else if(newValue) {
                                                dispatch(getUnitsFromCompany(newValue.id));
                                               
                                                formik.setFieldValue("company", newValue.id);
                                                setPreviewData({
                                                    ...previewData,
                                                    company: newValue.name
                                                })
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                id="company"
                                                name="company"
                                                label="Empresa"
                                                value={formik.values.company}
                                                error={formik.touched.company && Boolean(formik.errors.company)}
                                                helperText={formik.touched.company && formik.errors.company}
                                            />
                                        )}
                                    />
                                    {}
                                </FormControl>

                                <FormControl sx={{ mb: 1}}>
                                    <TextField
                                        fullWidth
                                        select
                                        id="property"
                                        name="property"
                                        label="Unidade"
                                        disabled={!(units.data.data && units.data.data.length > 0)}
                                        value={formik.values.property}
                                        error={formik.touched.property && Boolean(formik.errors.property)}
                                        helperText={formik.touched.property && formik.errors.property}
                                        onChange={e => {
                                            formik.handleChange(e)
                                            let name = "";

                                            units.data.data.map(dt => {
                                                if(dt.id == e.target.value) {
                                                    name = dt.address
                                                    setPropertyLocation({ lat: Number(dt.latitude), lng: Number(dt.longitude) });
                                                }
                                            })

                                            dispatch(getCustomers("", "", "", "", "", true, e.target.value));
                                           
                                            setPreviewData({
                                                ...previewData,
                                                property: name
                                            })
                                        }}
                                    >
                                        {
                                            units.data.data && units.data.data.map(property => <MenuItem value={property.id}>{property.address}</MenuItem>)
                                        }
                                    </TextField>
                                </FormControl>

                                <FormControl>
                                    <Autocomplete
                                        disablePortal
                                        options={customers.data.data || []}
                                        disabled={!formik.values.property}
                                        getOptionLabel={client => client.profile ? client.profile.name : ""}
                                        fullWidth
                                        noOptionsText="Busque por um cliente..."
                                        value={formik.values.client && customers.data.data && customers.data.data.length > 0
                                            ? customers.data.data.filter(cl => cl.id == formik.values.client)[0]
                                            : null
                                        }
                                        onBlur={() => {
                                            if(!formik.values.client && !initalized) {
                                                formik.setFieldValue("client", serviceOrders.data.responsible_user_id);
                                                setPreviewData({
                                                    ...previewData,
                                                    client: serviceOrders.data.responsible_user.profile.name
                                                });
                                            }
                                            dispatch(getCustomers("", "", "", "", "", true, formik.values.property))
                                        }}
                                        onChange={(event, newValue, reason) => {
                                            if(reason == "clear") {
                                                formik.setFieldValue("client", "");
                                                setPreviewData({
                                                    ...previewData,
                                                    client: null
                                                });
                                            } else if(newValue) {
                                                setInitialized(true);
                                                formik.setFieldValue("client", newValue.id);

                                                setPreviewData({
                                                    ...previewData,
                                                    client: newValue.profile.name
                                                })
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                id="client"
                                                name="client"
                                                label="Cliente"
                                                value={formik.values.client}
                                                error={formik.touched.client && Boolean(formik.errors.client)}
                                                helperText={formik.touched.client && formik.errors.client}
                                                onFocus={() => {
                                                    formik.setFieldValue("client", "");
                                                    setPreviewData(prev => ({
                                                        ...prev,
                                                        client: ""
                                                    }))
                                                }}
                                                onChange={e => {
                                                    dispatch(getCustomers("", "", "", "", e.target.value, true, formik.values.property));
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>

                                <CardHeader
                                    title="Produto"
                                    sx={{ mb: -1, ml: -2 }}
                                />

                                <Divider sx={{ mb: 2 }} />

                                <FormControl sx={{ mb: 1 }}>
                                    <Autocomplete
                                        disablePortal
                                        options={productFamilies.data.data || []}
                                        value={formik.values.family && productFamilies.data.data && productFamilies.data.data.length > 0
                                            ? productFamilies.data.data.filter(fa => fa.id == formik.values.family)[0] 
                                            : null}
                                        getOptionLabel={family => family.name}
                                        fullWidth
                                        onChange={(event, newValue, reason) => {
                                            if(reason == "clear") {
                                                dispatch({ type: CLEAN_PRODUCT_STORE });
                                                dispatch({ type: CLEAN_PRODUCT_MODEL_STORE });
                                                dispatch({ type: CLEAN_ISSUES_STORE });
                                                formik.setFieldValue("family", "");
                                                formik.setFieldValue("product", "");
                                                formik.setFieldValue("model", "");
                                                formik.setFieldValue("issue", "");
                                                setEnableProducts(false);
                                                setEnableModels(false);
                                                setEnableIssues(false);
                                                setPreviewData({
                                                    ...previewData,
                                                    family: "",
                                                    product: "",
                                                    model: "",
                                                    issue: ""
                                                })
                                            } else if(newValue) {
                                                formik.setFieldValue("family", newValue.id);

                                                setPreviewData({
                                                    ...previewData,
                                                    family: newValue.name
                                                })

                                                setEnableProducts(false);
                                                dispatch(setCategory(newValue.id));
                                                dispatch(getProduct("", "", "", "", "", true));
                                                setTimeout(() => setEnableProducts(true), 1000);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                id="family"
                                                name="family"
                                                label="Família"
                                                value={formik.values.family}
                                                error={formik.touched.family && Boolean(formik.errors.family)}
                                                helperText={formik.touched.family && formik.errors.family}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl sx={{ mb: 1 }}>
                                    <Autocomplete
                                        disablePortal
                                        options={product.data.data || []}
                                        getOptionLabel={product => product.name}
                                        fullWidth
                                        value={formik.values.product && product.data.data && product.data.data.length > 0
                                            ? product.data.data.filter(pr => pr.id == formik.values.product)[0] 
                                            : null}
                                        disabled={!enableProducts}
                                        onChange={(event, newValue, reason) => {
                                            if(reason == "clear") {
                                                dispatch({ type: CLEAN_PRODUCT_MODEL_STORE });
                                                dispatch({ type: CLEAN_ISSUES_STORE });
                                                formik.setFieldValue("product", "");
                                                formik.setFieldValue("model", "");
                                                formik.setFieldValue("issue", "");
                                                setEnableModels(false);
                                                setEnableIssues(false);
                                                setPreviewData({
                                                    ...previewData,
                                                    product: "",
                                                    model: "",
                                                    issue: ""
                                                })
                                            } else if(newValue) {
                                                formik.setFieldValue("product", newValue.id);

                                                setPreviewData({
                                                    ...previewData,
                                                    product: newValue.name
                                                })

                                                setEnableModels(false)

                                                dispatch(setCategory(newValue.id));
                                                dispatch(getProductModels("", "", "", "", "", true, true));

                                                setTimeout(() => setEnableModels(true), 1000);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                id="product"
                                                name="product"
                                                label="Produto"
                                                value={formik.values.product}
                                                error={formik.touched.product && Boolean(formik.errors.product)}
                                                helperText={formik.touched.product && formik.errors.product}
                                            />
                                        )}
                                    />                                    
                                </FormControl>
                                <FormControl sx={{ mb: 1 }}>
                                    <Autocomplete
                                        disablePortal
                                        options={productModels.data.data || []}
                                        disabled={!enableModels}
                                        getOptionLabel={model => model.name}
                                        fullWidth
                                        value={formik.values.model && enableModels && productModels.data.data && productModels.data.data.length > 0
                                            ? productModels.data.data.filter(mo => mo.id == formik.values.model)[0] 
                                            : null}
                                        onChange={(event, newValue, reason) => {
                                            if(reason == "clear") {
                                                dispatch({ type: CLEAN_ISSUES_STORE });
                                                formik.setFieldValue("model", "");
                                                formik.setFieldValue("issue", "");
                                                setEnableIssues(false);
                                                setPreviewData({
                                                    ...previewData,
                                                    model: "",
                                                    issue: ""
                                                })
                                            } else if(newValue) {
                                                formik.setFieldValue("model", newValue.id);
                                                const partsIds = [];

                                                newValue.product_model_parts.map(part => {
                                                    partsIds.push(part.id);
                                                });

                                                setProductPartsIds(partsIds);

                                                setPreviewData({
                                                    ...previewData,
                                                    model: newValue.name
                                                });

                                                setEnableIssues(false);
                                                dispatch(setCategory(newValue.id));
                                                dispatch(getIssues("", "", "", "", "", true));

                                                setTimeout(() => setEnableIssues(true), 1000);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                id="model"
                                                name="model"
                                                label="Modelo"
                                                value={formik.values.model}
                                                error={formik.touched.model && Boolean(formik.errors.model)}
                                                helperText={formik.touched.model && formik.errors.model}
                                            />
                                        )}
                                    />                                    
                                </FormControl>
                                <FormControl>
                                    <Autocomplete
                                        disablePortal
                                        options={issues.data.data || []}
                                        disabled={!enableIssues}
                                        getOptionLabel={issue => issue.name}
                                        fullWidth
                                        value={formik.values.issue && enableIssues && issues.data.data && issues.data.data.length > 0
                                            ? issues.data.data.filter(mo => mo.id == formik.values.issue)[0] 
                                            : null}
                                        onChange={(event, newValue, reason) => {
                                            if(reason == "clear") {
                                                formik.setFieldValue("issue", "");
                                                setPreviewData({
                                                    ...previewData,
                                                    issue: ""
                                                })
                                            } else if(newValue) {
                                                formik.setFieldValue("issue", newValue.id);
                                                setPreviewData({
                                                    ...previewData,
                                                    issue: newValue.name
                                                });
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                id="issue"
                                                name="issue"
                                                label="Problema"
                                                value={formik.values.issue}
                                                error={formik.touched.issue && Boolean(formik.errors.issue)}
                                                helperText={formik.touched.issue && formik.errors.issue}
                                            />
                                        )}
                                    />                                    
                                </FormControl>

                                <CardHeader
                                    title="Serviço"
                                    sx={{ mb: -1, ml: -2 }}
                                />

                                <Divider sx={{ mb: 2 }} />

                                <FormControl sx={{ mb: 1 }}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        id="description"
                                        name="description"
                                        label="Descrição da Ordem de Serviço"
                                        rows={2}
                                        value={formik.values.description}
                                        error={formik.touched.description && Boolean(formik.errors.description)}
                                        helperText={formik.touched.description && formik.errors.description}
                                        onChange={e => {
                                            formik.handleChange(e);
                                            setPreviewData({
                                                ...previewData,
                                                description: e.target.value
                                            })
                                        }}
                                    />
                                </FormControl>
                                <FormControl sx={{ mb: 1 }}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        id="internal_observations"
                                        name="internal_observations"
                                        label="Observações Internas"
                                        rows={2}
                                        value={formik.values.internal_observations}
                                        error={formik.touched.internal_observations && Boolean(formik.errors.internal_observations)}
                                        helperText={formik.touched.internal_observations && formik.errors.internal_observations}
                                        onChange={e => {
                                            formik.handleChange(e);
                                            setPreviewData({
                                                ...previewData,
                                                internal_observations: e.target.value
                                            })
                                        }}
                                    />
                                </FormControl>

                                <FormControl sx={{ mb: 1 }}>
                                    <TextField
                                        fullWidth
                                        id="invoice"
                                        name="invoice"
                                        label="Nota Fiscal"
                                        value={formik.values.invoice}
                                        error={formik.touched.invoice && Boolean(formik.errors.invoice)}
                                        helperText={formik.touched.invoice && formik.errors.invoice}
                                        onChange={e => {
                                            formik.handleChange(e);
                                            setPreviewData({
                                                ...previewData,
                                                invoice: e.target.value
                                            })
                                        }}
                                    />
                                </FormControl>

                                <FormControl sx={{ mb: 1 }}>
                                <Autocomplete
                                        disablePortal
                                        options={taskTypes.data.data}
                                        getOptionLabel={taskType => taskType.name}
                                        fullWidth
                                        value={formik.values.service_type && taskTypes.data.data && taskTypes.data.data.length > 0
                                            ? taskTypes.data.data.filter(tt => tt.id == formik.values.service_type)[0] 
                                            : null}
                                        onChange={(event, newValue, reason) => {
                                            if(reason == "clear") {
                                                formik.setFieldValue("service_type", "");
                                                setPreviewData({
                                                    ...previewData,
                                                    service_type: ""
                                                })
                                            } else if(newValue) {
                                                formik.setFieldValue("service_type", newValue.id);
                                                
                                                setPreviewData({
                                                    ...previewData,
                                                    service_type: newValue.name
                                                })
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                id="service_type"
                                                name="service_type"
                                                label="Tipo de Serviço"
                                                value={formik.values.service_type}
                                                error={formik.touched.service_type && Boolean(formik.errors.service_type)}
                                                helperText={formik.touched.service_type && formik.errors.service_type}
                                            />
                                        )}
                                    />                                   
                                </FormControl>

                                <FormControl sx={{ mb: 1 }}>     
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker 
                                            disablePast
                                            id="date"
                                            name="date"
                                            label="Início"
                                            format="DD/MM/YYYY"
                                            minDate={moment().utc()}
                                            onAccept={newValue => {
                                                if(newValue) {
                                                    formik.setFieldValue("scheduled_at", newValue, true)
                                                    const date = newValue.format("DD/MM/YYYY")
                                                    
                                                    setPreviewData(prev => ({                                                        
                                                        ...prev,
                                                        scheduled_at: [date, prev.scheduled_at[1]],
                                                    }))

                                                    if(formik.values['end']) {
                                                        const end = formik.values['end'];

                                                        const diffEnd = end.diff(newValue, "d");

                                                        if(diffEnd < 0) {
                                                            formik.setFieldValue("end", newValue);

                                                            setPreviewData(prev => ({
                                                                ...prev,
                                                                endAt: [newValue.format("DD/MM/YYYY"), prev.endAt[1]]
                                                            }))
                                                        }
                                                    }
                                                }
                                            }}
                                            onChange={newValue => {
                                                if(newValue && newValue.format("DD/MM/YYYY") != "Invalid Date") {
                                                    formik.setFieldValue("scheduled_at", newValue, true)
                                                    const date = newValue.format("DD/MM/YYYY")
                                                    
                                                    setPreviewData({                                                        
                                                        ...previewData,
                                                        scheduled_at: [date, previewData.scheduled_at[1]],
                                                    })
                                                }
                                            }}
                                            value={formik.values.scheduled_at}
                                            slotProps={{
                                                textField: {
                                                    variant: "outlined",
                                                    error: formik.touched.scheduled_at && Boolean(formik.errors.scheduled_at),
                                                    helperText: formik.touched.scheduled_at && formik.errors.scheduled_at,
                                                    onBlur: () => {
                                                        if(formik.values['scheduled_at']) {
                                                            const today = dayjs().startOf("day");
                                                            const date = formik.values['scheduled_at'];
                                                            const diff = date.diff(today, "d");

                                                            setPreviewData(prev => ({
                                                                ...prev,
                                                                scheduled_at: [date.format("DD/MM/YYYY"), prev.scheduled_at[1]]
                                                            }))
                                                            
                                                            if(diff < 0) {
                                                                formik.setFieldValue("scheduled_at", today);

                                                                setPreviewData(prev => ({
                                                                    ...prev,
                                                                    scheduled_at: [today.format("DD/MM/YYYY"), prev.scheduled_at[1]]
                                                                }))
                                                            }

                                                            if(formik.values['end']) {
                                                                const end = formik.values['end'];

                                                                const diffEnd = end.diff(date, "d");

                                                                if(diffEnd < 0) {
                                                                    formik.setFieldValue("end", date);
    
                                                                    setPreviewData(prev => ({
                                                                        ...prev,
                                                                        endAt: [date.format("DD/MM/YYYY"), prev.endAt[1]]
                                                                    }))
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                                
                                <FormControl sx={{ mb: 1 }}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={!showFreeTimes}
                                        id="time"
                                        name="time"
                                        label="Horário Início"
                                        value={formik.values.time}
                                        error={formik.touched.time && Boolean(formik.errors.time)}
                                        helperText={formik.touched.time && formik.errors.time}
                                        onChange={e => {
                                            formik.handleChange(e);

                                            setPreviewData({
                                                ...previewData,
                                                scheduled_at: [previewData.scheduled_at[0], e.target.value]
                                            })
                                        }}
                                    >
                                        <MenuItem value="08:00">08:00</MenuItem>
                                        <MenuItem value="09:00">09:00</MenuItem>
                                        <MenuItem value="10:00">10:00</MenuItem>
                                        <MenuItem value="11:00">11:00</MenuItem>
                                        <MenuItem value="12:00">12:00</MenuItem>
                                        <MenuItem value="13:00">13:00</MenuItem>
                                        <MenuItem value="14:00">14:00</MenuItem>
                                        <MenuItem value="15:00">15:00</MenuItem>
                                        <MenuItem value="16:00">16:00</MenuItem>
                                        <MenuItem value="17:00">17:00</MenuItem>
                                        <MenuItem value="18:00">18:00</MenuItem>
                                        <MenuItem value="19:00">19:00</MenuItem>
                                        <MenuItem value="20:00">20:00</MenuItem>
                                        <MenuItem value="21:00">21:00</MenuItem>
                                        <MenuItem value="22:00">22:00</MenuItem>
                                    </TextField>
                                </FormControl>

                                <FormControl sx={{ mb: 1 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker                                            
                                            disablePast
                                            disabled={!showFreeTimes}
                                            id="end"
                                            name="end"
                                            label="Final"
                                            format="DD/MM/YYYY"
                                            minDate={formik.values.scheduled_at}
                                            value={formik.values.end}
                                            onChange={newValue => {
                                                if(newValue && newValue.format("DD/MM/YYYY") != "Invalid Date") {
                                                    formik.setFieldValue("end", newValue, true)
                                                    const date = newValue.format("DD/MM/YYYY")
                                                    
                                                    setPreviewData(prev => ({                                                        
                                                        ...prev,
                                                        endAt: [date, previewData.endAt[1]],
                                                    }))
                                                }
                                            }}
                                            slotProps={{
                                                textField: {
                                                    variant: "outlined",
                                                    error: formik.touched.end && Boolean(formik.errors.end),
                                                    helperText: formik.touched.end && formik.errors.end,
                                                    onBlur: () => {
                                                        if(formik.values['end']) {
                                                            const start = formik.values['scheduled_at'];
                                                            const end = formik.values['end'];

                                                            const diff = end.diff(start, "d");

                                                            if(diff < 0) {
                                                                formik.setFieldValue("end", start);
                                                                setPreviewData({                                                        
                                                                    ...previewData,
                                                                    endAt: [start.format("DD/MM/YYYY"), previewData.endAt[1]],
                                                                })
                                                            }
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                                
                                <FormControl sx={{ mb: 1 }}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={!showFreeTimes}
                                        id="time_end"
                                        name="time_end"
                                        label="Horário Final"
                                        value={formik.values.time_end}
                                        error={formik.touched.time_end && Boolean(formik.errors.time_end)}
                                        helperText={formik.touched.time_end && formik.errors.time_end}
                                        onChange={e => {
                                            formik.handleChange(e);

                                            setPreviewData({
                                                ...previewData,
                                                endAt: [previewData.endAt[0], e.target.value]
                                            })
                                        }}
                                    >
                                        {
                                            freeTimes.length > 0
                                            ? freeTimes.map(freeTime => <MenuItem value={freeTime}>{freeTime}</MenuItem>)
                                            : <MenuItem value={null} disabled>Nenhum horário livre para esse dia.</MenuItem>
                                        } 
                                    </TextField>
                                </FormControl>

                                {
                                    (auth.user && auth.user.role[0].name != "RESALE") && (
                                        <FormControl sx={{ mb: 1 }}>
                                            <TextField
                                                fullWidth
                                                select
                                                id="technicians_origin"
                                                name="technicians_origin"
                                                label="Origem dos Técnicos"
                                                value={formik.values.technicians_origin}
                                                error={formik.touched.technicians_origin && Boolean(formik.errors.technicians_origin)}
                                                helperText={formik.touched.technicians_origin && formik.errors.technicians_origin}
                                                onChange={e => {
                                                    setShow(false)
                                                    formik.setFieldValue("responsibles", []);
                                                    
                                                    formik.handleChange(e, { shouldValidate: true });
                                                    
                                                    if(e.target.value == "krebsfer") {
                                                        setTimeout(() => {
                                                            setShow(true);    
                                                        }, 100);
                                                    }
                                                }}
                                            >
                                                <MenuItem value="revenda">Revenda</MenuItem>
                                                <MenuItem value="krebsfer">KREBSFER INDUSTRIAL</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    )
                                }

                                {
                                    ((auth.user && auth.user.role[0].name != "RESALE") && formik.values.technicians_origin == "revenda") && (
                                        <FormControl sx={{ mb: 1 }}>
                                            <TextField
                                                fullWidth
                                                select
                                                id="resale"
                                                name="resale"
                                                label="Revenda"
                                                value={formik.values.resale}
                                                error={formik.touched.resale && Boolean(formik.errors.resale)}
                                                helperText={formik.touched.resale && formik.errors.resale}
                                                onChange={e => {
                                                    formik.handleChange(e, { shouldValidate: true });
                                                    
                                                    setTimeout(() => {
                                                        setShow(true);    
                                                    }, 100);
                                                }}
                                            >
                                                {
                                                    resales.data && resales.data.map(revenda => <MenuItem value={revenda.id}>{revenda.name}</MenuItem>)
                                                }
                                            </TextField>
                                        </FormControl>
                                    )
                                }

                                {
                                    (show || (auth.user && auth.user.role[0].name == "RESALE")) && (
                                        <FormControl sx={{ mb: 1 }}>
                                            <Autocomplete
                                                multiple
                                                id="tags-outlined"
                                                options={responsibles}
                                                value={technicians}
                                                getOptionLabel={(option) => option.profile ? option.profile.name : ""}
                                                filterSelectedOptions
                                                disabled={disableTecsSearch()}
                                                onChange={(e, newValue) => {
                                                    setResponsibles([]);

                                                    if(newValue.length > 0) {
                                                        setTechnicians(newValue)
                                                        const valueFormated = newValue.map(vl => vl.id);
                                                        const namesArray = newValue.map(vl => vl.profile.name);

                                                        formik.setFieldValue("responsibles", valueFormated);
                                                        setPreviewData({
                                                            ...previewData,
                                                            responsibles: namesArray.join(", ")
                                                        })
                                                    } else {
                                                        setTechnicians([]);
                                                        formik.setFieldValue("responsibles", []);
                                                        setPreviewData({
                                                            ...previewData,
                                                            responsibles: ""
                                                        })
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        id="responsibles"
                                                        name="responsibles"
                                                        label={disableTecsSearch() ? "Selecione um período para buscar técnicos" : "Técnicos Responsáveis"}
                                                        onChange={e => {
                                                            searchResponsibles(e.target.value, { shouldValidate: true });
                                                        }}
                                                        value={formik.values.responsibles}
                                                        error={formik.touched.responsibles && Boolean(formik.errors.responsibles)}
                                                        helperText={formik.touched.responsibles && formik.errors.responsibles}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    )
                                }
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={showImagePicker}
                                            onChange={e => setShowImagePicker(e.target.checked)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                        label="Deseja inserir imagens?"
                                    />
                                </FormGroup>

                                {
                                    showImagePicker && (
                                        <FormControl>
                                            <InputLabel>Imagens do Equipamento</InputLabel>
                                            <DzPreviews selectFiles={setImages} image />
                                        </FormControl>
                                    )
                                }

                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={showVideoPicker}
                                            onChange={e => setShowVideoPicker(e.target.checked)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                        label="Deseja inserir vídeos?"
                                    />
                                </FormGroup>

                                {
                                    showVideoPicker && (
                                        <FormControl>
                                            <InputLabel>Vídeos do Equipamento</InputLabel>
                                            <DzPreviews selectFiles={setVideos} video />
                                        </FormControl>
                                    )
                                }
                                
                                <Button
                                    variant="contained"
                                    color="error"
                                    type="submit"
                                    sx={{
                                        mt: 5
                                    }}
                                >
                                    Salvar
                                </Button>
                            </Div>
                        </form>

                        <Stack direction="column" flex={1} p={5}>
                            {
                                propertyLocation
                                    ? <SimpleMap location={propertyLocation} />
                                    : (
                                        <Stack width="100%" height="400px" alignItems="center">
                                            <Lottie
                                                animationData={locationAnimation}
                                                loop={true}
                                                style={{
                                                    height: 350
                                                }}
                                            />
                                            <ListItemText primary="Selecione uma propriedade" />
                                        </Stack>
                                    )
                            }

                            <Div>
                                <CardHeader
                                    title="Dados da Ordem de Serviço"
                                    sx={{ mb: -1, ml: -2 }}
                                />
                                <Divider sx={{ mb: 2 }} />
                                
                                <List
                                    disablePadding
                                    sx={{ width: "100%" }}
                                >                                  
                                    <ListItem alignItems="flex-start" sx={{width:'80%', display: 'grid', gridRow: '1', gridTemplateColumns: 'repeat(2,1fr)', rowGap: 2, p: theme => theme.spacing(1.25, 1)}}>
                                        <ListItemText primary="Empresa" />
                                        <ListItemText secondary={previewData.company} />
                                        <ListItemText primary="Unidade" />
                                        <ListItemText secondary={previewData.property} />
                                        <ListItemText primary="Cliente" />
                                        <ListItemText secondary={previewData.client} />
                                        <ListItemText primary="Família" />
                                        <ListItemText secondary={previewData.family} />
                                        <ListItemText primary="Produto" />
                                        <ListItemText secondary={previewData.product} />
                                        <ListItemText primary="Modelo" />
                                        <ListItemText secondary={previewData.model} />
                                        <ListItemText primary="Problema" />
                                        <ListItemText secondary={previewData.issue} />
                                        <ListItemText primary="Descrição" />
                                        <ListItemText secondary={previewData.description} />
                                        <ListItemText primary="Observações Internas" />
                                        <ListItemText secondary={previewData.internal_observations} />
                                        <ListItemText primary="Nota Fiscal" />
                                        <ListItemText secondary={previewData.invoice} />
                                        <ListItemText primary="Tipo de Serviço" />
                                        <ListItemText secondary={previewData.service_type} />
                                        <ListItemText primary="Responsáveis" />
                                        <ListItemText secondary={previewData.responsibles} />
                                        <ListItemText primary="Agendado para" />
                                        <ListItemText secondary={`${previewData.scheduled_at[0] != "" && !previewData.scheduled_at[0].includes("inválida") ? previewData.scheduled_at[0] : ""} ${previewData.scheduled_at[1]} ${previewData.endAt[0] != "" && !previewData.endAt[0].includes("inválida") ? `até ${previewData.endAt[0]} ${previewData.endAt[1]}` : ""}`} />
                                    </ListItem>
                                </List>
                            </Div>
                        </Stack>
                    </Stack>
                </JumboContent>
            </JumboCardQuick>
        </Div>
    );
}