import React, {Suspense, useEffect} from 'react';
import {IconButton} from "@mui/material";
import menus from "./menus";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import {DrawerHeader} from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {useJumboLayoutSidebar, useJumboSidebarTheme} from "@jumbo/hooks";
import {SIDEBAR_STYLES, SIDEBAR_VIEWS} from "@jumbo/utils/constants/layout";
import Logo from "../../../../shared/Logo";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import SidebarSkeleton from "./SidebarSkeleton";
import { appTheme } from 'app/themes/app/theme';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { asyncNotificationsActions } from 'app/redux/reducers';
import OneSignal from 'react-onesignal';
import { set } from 'lodash';
import { oneSignal } from 'app/config/onesignal';

const Sidebar = () => {
    const { auth } = useSelector(state => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [initialized, setInitialized] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    


    // React.useEffect(() => {
    //     if(auth.token){
    //     if (!initialized) {
    //       try {
    //         OneSignal.init({
    //           appId: oneSignal().appId,
    //           allowLocalhostAsSecureOrigin: true,
    //           safari_web_id: "web.onesignal.auto.01d22b73-bccb-4a84-9633-69c73285f3f4",
    //           notifyButton: { enable: false },
    //           promptOptions: {
    //             slidedown: {
    //               enabled: true,
    //               actionMessage: "Receba notificação de quando os Tickets estiverem em aberto aguardando resposta.",
    //               acceptButtonText: "Permitir",
    //               cancelButtonText: "Não"
    //             }
    //           }
    //         }).then(() => {
    //           OneSignal.setConsentRequired(true);
      
    //           OneSignal.User.addAlias("krebs-os-react", auth.token);

    //           setInitialized(true);
    //         });

    //       } catch (e) {
    //         console.log(e);
    //       }
    //     }
    // }

    //     setLoading(false)
    //   }, []);



    
    // const assignPlayerId = async() => {
       
    //     if(id){
    //         dispatch(asyncNotificationsActions.post(id))
    //         console.log('registro do onesignal')
    //     }

    // }
    useEffect(() => {
        if(!auth.token){
            navigate('/login');
        }
    }, []);


    return (
        <React.Fragment>
            <SidebarHeader/>
            <JumboScrollbar
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
            >
                <Suspense
                    fallback={
                        <Div
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                alignContent: 'center',
                                px: 3,
                                
                            }}
                        >
                            <SidebarSkeleton/>
                        </Div>
                    }
                >
                    <JumboVerticalNavbar translate items={menus}/>
                </Suspense>
            </JumboScrollbar>
        </React.Fragment>
    );
};

const SidebarHeader = () => {
    const {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();
    const {sidebarTheme, setSidebarTheme} = useJumboSidebarTheme();

    
    React.useEffect(() => {
        setSidebarTheme({
            ...sidebarTheme,
            overlay: {
                // backgroundImage: `linear-gradient(${activeBgColorOption?.colors[0]}, ${activeBgColorOption?.colors[1]})`,
                bgColor:  [appTheme.palette.principal, appTheme.palette.focus],
                opacity: 0.85
            }
        })
    }, []);
    

    const isMiniAndClosed = React.useMemo(() => {
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]);

    return (
        <React.Fragment>
            {
                sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                <DrawerHeader>
                    <Logo mini={isMiniAndClosed} mode={sidebarTheme.type}/>
                    {
                        <Zoom in={sidebarOptions?.open}>
                            <IconButton
                                edge="start"
                                color={appTheme.palette.neutral100}
                                aria-label="open drawer"
                                sx={{ml: 0, mr: -1.5, color: "#FFF"}}
                                onClick={() => setSidebarOptions({open: false})}
                            >
                                <MenuOpenIcon color={appTheme.palette.neutral100} />
                            </IconButton>
                        </Zoom>
                    }
                </DrawerHeader>
            }
        </React.Fragment>
    )
};

export default Sidebar;
