import { Chip } from '@mui/material';
import React, { useState } from 'react';

const ChipStatus = ({ status }) => {
        let chip = {
          color: "",
          text: ""
        }
        switch(status){
          case 1:
            chip.color = "red"
            chip.text = "Aberto"
            break
          case 2:
            chip.color = "#1976d2"
            chip.text = "Pendente"
            break
          case 3:
            chip.color = "#9575cd"
            chip.text = "Resolvido"
            break
          case 4:
            chip.color = "#212121"
            chip.text = "Fechado"
            break
          case 5:
            chip.color = "#607d8b"
            chip.text = "Suspenso" 
            break
          default:
            chip.color = "error"
            chip.text = "Aberto" 
            break
        }
        return(
          <Chip
          label={chip.text}
          variant="filled"
          style={{backgroundColor: chip.color, color: "white"}}
        />
      )
    
}
export default ChipStatus;