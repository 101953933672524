import {
  asyncCompaniesActions,
  asyncCustomersActions,
  asyncProductFamiliesActions,
  asyncTicketsActions,
  asyncTicketsCategoriesActions,
  asyncTicketsResponsesActions,
  asyncUnitsActions,
  asyncUserSacActions,
} from "app/redux/reducers";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  FormControl,
  Icon,
  IconButton,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import "./style.css";
import { colors } from "app/utils/Colors";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import ChipStatus from "app/components/ChipStatus";
import SplitButton from "app/components/SplitButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { normalizeDate, renderAvatar, firstLetter } from "app/utils/appHelpers";
import { CLEAN_TICKETS_STORE } from "app/redux/modules/Tickets/constants";
import { CLEAN_TICKETS_RESPONSES_STORE } from "app/redux/modules/TicketsResponse/constants";
import { Formik, Form } from "formik";
import { debounce } from "lodash";
import { CLEAN_CUSTOMERS_STORE } from "app/redux/modules/Users/Customers/constants";
import * as Yup from "yup";
import draftToHtml from "draftjs-to-html";
import { CLEAN_UNITS_STORE } from "app/redux/modules/Customers/Units/constants";
import SwitchTickets from "app/components/SwitchTickets";
import { WhatsApp, Mail, CloudDone} from '@mui/icons-material';
import api from "../../services/config";
import TicketsSkeleton from './Skeleton';
import { CLEAN_PRODUCT_FAMILIES_STORE } from "app/redux/modules/Products/families/constants";
import { array } from "prop-types";

const TicketDetails = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [switchState, setSwitchState] = React.useState(true);
  const [image, setImage] = React.useState(null);

  const { ticketsResponses, customers, sac, auth, ticketCC, tickets, ticketsCategories, companies, productFamilies, units } = useSelector(
    (state) => state
  );

  const icons = [
    <WhatsApp className={'h-3 w-3 self-center'} />,
    <Mail className={'h-3 w-3 self-center'} />,
    <CloudDone className={'h-3 w-3 self-center'} />
  ]
   
  
  const throgleSwitch = (value) => {
    setSwitchState(value);
  };


  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  
  const startCreatingPage = async() =>{
    if(id){
      dispatch(asyncTicketsActions.getSingle(id));
      dispatch(asyncTicketsResponsesActions.get(id));
    }
  }

  const fetchPromisse = async() => {

    const fetchs = [
    dispatch(asyncTicketsActions.ccUsers()),
    dispatch(asyncUserSacActions.get("name","desc",1,1,"",true,"1,4")),
    dispatch(asyncTicketsCategoriesActions.get("name","desc",1,1,"",true)),
    dispatch(asyncCompaniesActions.getCompanies("name","desc",1,1,"",true)),
    dispatch(asyncProductFamiliesActions.getProductFamilies("name","desc",1,1,"",true))

    ]

    if(id){
      fetchs.push(
        dispatch(asyncCustomersActions.getCustomers("name","desc",1,1,"",true,tickets?.data?.responsible_user?.profile?.unit.id)),
        dispatch(asyncUnitsActions.getUnitsFromCompany(tickets?.data?.responsible_user?.profile?.unit?.company?.id)))     
    }

    await Promise.all(fetchs.map(disp => disp
      .then((res) => res)))
      .then(() => setLoading(false))
      .catch(error => console.log(error))   
  }

  useEffect(async() => {
    try{
     await startCreatingPage()
     await fetchPromisse()
    }catch(error){
      console.log(error)
    }
  
    return () => {
      dispatch({type: CLEAN_TICKETS_STORE})
      dispatch({type: CLEAN_TICKETS_RESPONSES_STORE})
      dispatch({type: CLEAN_CUSTOMERS_STORE})
      dispatch({type: CLEAN_UNITS_STORE})
      dispatch({type: CLEAN_UNITS_STORE})
      dispatch({type: CLEAN_CUSTOMERS_STORE})
    }
  }, [id]);

  
  const handleClickCompany = (id) => {
    navigate(`/tickets/empresa/${id}`);
  };

  const handleClickUser = (id) => {
    navigate(`/tickets/cliente/${id}`);
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleEdit = async(values) => {
    if (editorState.getCurrentContent().hasText()) {
      await handleSubmitAnswer();
    }
    const data = {
      title: values.title,
      ticket_channel_id: 3,
      ticket_category_id: values.category,
      ticket_status_id: values.status,  
      user_id: values.attendant,
      unit_id: values.unit,
      cc: values.cc,
      responsible_user_id: values.client,
      product_family_id: values.family,
    }
    dispatch(asyncTicketsActions.edit(data, tickets.data.id)).then(() => 
          navigate(`/tickets`),

      )
  };

 
  const handleCreate= (values) => {
    const data = {
      title: values.title,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      ticket_channel_id: 3,
      ticket_category_id: values.category,
      ticket_status_id: values.status,  
      responsible_user_id: values.client,
      user_id: values.attendant,
      cc: values.cc,
      ticket_response_is_public: switchState,
      product_family_id: values.family,
    }
    dispatch(asyncTicketsActions.create(data)).then(() => 
       navigate(`/tickets`)

    )

  } 

  
 
  const isHtml = (str) => {
    const htmlRegex = /(<([^>]+)>)/gi;
    return htmlRegex.test(str);
  };

  const validationSchema = Yup.object().shape({
    client: Yup.string().required("Obrigatório"),
    title: Yup.string().required("Obrigatório"),
    family: Yup.string().required("Obrigatório"),
    category: Yup.string().required("Obrigatório"),
    attendant: Yup.string().required("Obrigatório"),
    company: Yup.string().required("Obrigatório"),
    unit: Yup.string().required("Obrigatório"),

  });

  const handleSubmitAnswer = async() => {
    const dataAnswer = {
      response: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      ticket_id: id,
      is_public: switchState,
    };

    dispatch(asyncTicketsResponsesActions.post(dataAnswer));
  };

  const handleUploadMedia = async () => {
    try {
      const formData = new FormData();

      formData.append(`files`, image)

      const { data } = await api.post(
        "/files-upload",
        formData,
        { headers: { Authorization: `Bearer ${auth.token}`, "Content-Type": "multipart/form-data", } }
        );

        console.log("UPLOAD RESULT > > ", data)

        const dataAswer = {
          response: `<img src="https://storage.googleapis.com/focalweb/${data[0].name}" />`,
          ticket_id: id,
          is_public: switchState,
        };
    
        dispatch(asyncTicketsResponsesActions.post(dataAswer));
    } catch (error) {
      console.log("error upload > ", error);
    }
  }

  React.useEffect(() => {
    if(image) {
      /* const contentStateWithEntity = editorState.getCurrentContent().createEntity("image", "IMMUTABLE", { src: image.preview, width: 10, height: 50 })

      EditorState.set(editorState, {
        currentContent: contentStateWithEntity
      });
 */
    }
  }, [image]);

  const uploadCallback = (file, callback) => {
    return new Promise((resolve, reject) => {
      const fileImage = Object.assign(file, {
        preview: URL.createObjectURL(file),
      })

      const formData = new FormData();

      formData.append(`files`, fileImage)

      api.post(
        "/files-upload",
        formData,
        { headers: { Authorization: `Bearer ${auth.token}`, "Content-Type": "multipart/form-data", } }
      ).then(({data}) => {
        resolve({ data: { link: `https://storage.googleapis.com/focalweb/${data[0].name}` } });
      }).catch(error => {
        reject(error);
      });
    });
    /* return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      console.log(reader);
      reader.onloadend = async () => {
        const form_data = new FormData();
        form_data.append("file", file);
        const res = await uploadFile(form_data);
        setValue("thumbnail", res.data);
        resolve({ data: { link: process.env.REACT_APP_API + res.data } });
      };
      reader.readAsDataURL(file);
    }); */
  };

  
  return (
    <>
    {loading
    ? (<TicketsSkeleton/>)
    : (<>

      <input
        type="file"
        accept="image/*"
        id="image_response_input"
        style={{ display: "none" }}
        onChange={(e) => {
          const file = e.target.files[0];

          setImage(Object.assign(file, {
              preview: URL.createObjectURL(file),
          }));
      }}
      />

     <Div style={$headerBlock}>

            {tickets.data.id && (
              <>
                <Div onClick={() => handleClickCompany(tickets?.data?.responsible_user?.profile?.unit?.company.id)} style={{ ...$tagsTop, ...$tagsTopLeft }}>
                  {tickets.data?.responsible_user.profile?.unit?.company?.name}

                </Div>
                <Div onClick={() => handleClickUser(tickets.data?.responsible_user?.id)} style={$tagsTop}>
                  {tickets.data.responsible_user?.profile?.name}
                </Div>
              </>
            )}
            <Div style={{ ...$tagsTop, ...$tagsTopRight }}>
              {tickets.data.id ? (
                <>
                  <ChipStatus status={tickets.data.status?.id} />{" "}
                  <span style={{ padding: 6 }}>Ticket #{tickets.data.id}</span>
                </>
              ) : (
                <span style={{ padding: 6 }}>Criação de Ticket</span>
              )}
        </Div>
    </Div>
    <Formik
      initialValues={{
        title: tickets.data.title || "",
        description: tickets.data.description || "",
        category: tickets.data.category?.id || "",
        status: tickets.data.status?.id ?? "",
        attendant: tickets.data.attendant?.id || "",
        client: tickets.data.responsible_user?.id || "",
        company: tickets.data.responsible_user?.profile?.unit?.company?.id || "",
        unit: tickets.data.responsible_user?.profile.unit_id || "",
        cc: tickets?.data?.users_cc?.map((item) => item.id) || [],
        family: tickets?.data?.product_family?.id || "",
      }}
      onSubmit={(values) => {
        tickets?.data?.title 
        ? handleEdit(values) 
        : handleCreate(values)
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        setFieldValue,
        resetForm,
        
      }) => (
        <Form style={$form}>
          <Div style={$container}>
            {asyncTicketsActions.loading && <div>Carregando...</div>}            
            <Stack style={$leftBlock}>
              <Div style={$leftBlockRequestInfo}>
                {!id && (
                  <FormControl sx={{ width: "100%", marginBottom: "20px" }}>
                    <TextField
                      fullWidth
                      sx={{
                        zIndex: 0,
                        backgroundColor: colors.standardWhite,
                        borderColor: colors.borderColors,
                      }}
                      id={"title"}
                      name={"title"}
                      label={"Título do Ticket"}
                      InputProps={{
                        autoComplete: "off",
                      }}
                      onChange={handleChange}                      
                      error={touched.title && Boolean(errors.title)}
                      helperText={touched.title && errors.title}
                    ></TextField>
                  </FormControl>
                )}
                <FormControl sx={{ width: "100%", backgroundColor: "#ffffff" }}>
                <Autocomplete
                              filterSelectedOptions                              
                                options={sac.data.data}
                                fullWidth
                                InputProps={{
                                  autoComplete: "off",
                                }}
                                onChange={(event, value) => 
                                  setFieldValue(
                                    "attendant",
                                    value !== null
                                      ? value.id
                                      // : values.attendant
                                      : ""
                                  ) 
                                }
                                defaultValue={sac?.data?.data?.find(
                                  (option) => option.id === values.attendant
                                )}
                                getOptionLabel={(option) => option.profile.name}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    id={"attendant"}
                                    name={"attendent"}
                                    label={"Responsável pelo Atendimento"}
                                    error={touched.attendant && Boolean(errors.attendant)}
                                    helperText={touched.attendant && errors.attendant}

                                  />
                                )}
                              />
                </FormControl>
                {/* <FormControl sx={{ width: "100%", backgroundColor: "#ffffff", marginTop: "20px" }}>                     
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={ticketCC?.data?.data}
                            getOptionLabel={(option) => option?.profile?.name}
                            filterSelectedOptions
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setFieldValue("cc", newValue?.map(option => option.id));
                              }
                            }}
                            defaultValue={ticketCC?.data?.data.filter(option => values.cc.includes(option.id))}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    id="cc"
                                    name="cc"
                                    label={"CC"}                            
                                    value={values.cc}
                                    error={touched.cc && Boolean(errors.cc)}
                                    helperText={touched.cc && errors.cc}
                                />
                            )}
                        />

                </FormControl> */}
                <FormControl sx={{ width: "100%", backgroundColor: "#ffffff", marginTop: "20px" }}>    
                        <Autocomplete
                            options={ticketsCategories.data.data}
                            getOptionLabel={(option) => option?.name}
                            filterSelectedOptions
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setFieldValue("category", newValue.id);
                              } else {
                                setFieldValue("category", "")
                              }
                            }}
                            defaultValue={ticketsCategories?.data?.data?.find(
                              (option) => option.id === values.category
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    id="category"
                                    name="category"
                                    label={"Categoria do Ticket"}                            
                                    value={values.category}
                                    error={touched.category && Boolean(errors.category)}
                                    helperText={touched.category && errors.category}
                                />
                            )}
                        />
                </FormControl>
   

                <FormControl sx={{ width: "100%", backgroundColor: "#ffffff", marginTop: "20px" }}>    
                        <Autocomplete
                            options={companies.data.data}
                            getOptionLabel={(option) => option?.name}
                            filterSelectedOptions
                            InputProps={{
                              autoComplete: "off",
                            }}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setFieldValue("company", newValue.id);
                                dispatch(asyncUnitsActions.getUnitsFromCompany(newValue.id))       
                              } else {
                                setTimeout(() => {
                                  setFieldValue("company", ""); 
                                  setFieldValue("unit", "");
                                  setFieldValue("client", "");
                                }, 0)
                              }
                              dispatch({type: CLEAN_CUSTOMERS_STORE})
                            }}
                            defaultValue={companies?.data?.data.find(
                              (option) => option.id === values.company,
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    id="company"
                                    name="company"
                                    label={"Empresa"}                            
                                    value={values.company}
                                    error={touched.company && Boolean(errors.company)}
                                    helperText={touched.company && errors.company}
                                />
                            )}
                        />
                </FormControl>

                <FormControl sx={{ width: "100%", backgroundColor: "#ffffff", marginTop: "20px" }}>    
                              
                              <TextField
                                fullWidth
                                select
                                label={"Unidade"}
                                id={"unit"}
                                name={"unit"}
                                disabled={values.company === ""}
                                value={values.unit}
                                error={touched.unit && Boolean(errors.unit)}
                                helperText={touched.unit && errors.unit}
                                onChange={(event) => {
                                  setFieldValue(
                                    'unit',
                                    event.target.value !== null
                                      ? event.target.value
                                      : values.unit
                                  )
                                  dispatch(asyncCustomersActions.getCustomers("name","desc",1,1,"",true, event.target.value));

                                }

                                }
                              >
                                {
                                  units?.data?.data?.map(unit => <MenuItem value={unit.id}>{unit.address}</MenuItem>)
                                }                                     
                              </TextField>

                </FormControl>

                
                <FormControl sx={{ width: "100%", backgroundColor: "#ffffff", marginTop: "20px" }}>                                  
                              <TextField
                                fullWidth
                                select
                                label={"Cliente"}
                                id={"client"}
                                name={"client"}
                                disabled={units?.data?.length  === 0 || customers?.data?.length  === 0 }
                                value={values.client}
                                error={touched.client && Boolean(errors.client)}
                                helperText={touched.client && errors.client}
                                onChange={(event) => {
                                  setFieldValue(
                                    'client',
                                    event.target.value !== null
                                      ? event.target.value
                                      : values.client
                                  )

                                }

                                }
                              >
                                {
                                  customers?.data?.data?.map(client => <MenuItem value={client.id}>{client.profile.name}</MenuItem>)
                                }                                     
                              </TextField>
                </FormControl>

                <FormControl sx={{ width: "100%", backgroundColor: "#ffffff", marginTop: "20px" }}>                                  
                    <Autocomplete
                                options={productFamilies.data.data}
                                getOptionLabel={(option) => option?.name}
                                filterSelectedOptions
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    setFieldValue("family", newValue.id);
                                  } else {
                                    setFieldValue("family", "")
                                  }
                                }}
                                defaultValue={productFamilies?.data?.data?.find(
                                  (option) => option.id === values.family
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        id="family"
                                        name="family"
                                        label={"Família de Produtos"}                            
                                        value={values.family}
                                        error={touched.family && Boolean(errors.family)}
                                        helperText={touched.family && errors.family}
                                    />
                                )}
                            />
                </FormControl>

                

                {/* <FormControl sx={{ width: "100%", backgroundColor: "#ffffff", marginTop: "20px" }}>    
                        <Autocomplete
                            options={customers?.data?.data ? customers?.data?.data : []}
                            getOptionLabel={(option) => option?.profile?.name}
                            loading={customers.loading}
                            loadingText={"Carregando..."}
                            filterSelectedOptions
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setFieldValue("client", newValue.id);
                              }
                              

                            }}
                            value={customers?.data?.data?.find(
                              (option) => option.id === values.client
                            )}                            
                            disabled={units?.data?.length  === 0 || customers?.data?.length  === 0 }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    id="client"
                                    name="client"
                                    label={"Cliente"}                            
                                    value={values.client}
                                    error={touched.client && Boolean(errors.client)}
                                    helperText={touched.client && errors.client}
                                />
                            )}
                        />
                </FormControl> */}
           
              </Div>
            </Stack>
            <Stack className={"midBlock"} style={$midBlock}>
            {tickets?.data?.title && (
                <Div style={$titleTicketBlock}>
                  
                  {tickets?.data?.responsible_user?.profile?.avatar ? (
                    <Div>{renderAvatar(tickets?.data?.responsible_user?.profile?.avatar)}</Div>
                  ) : (
                    <Div>{firstLetter(tickets?.data?.responsible_user?.profile?.name)}</Div>
                  )}

                  <Div>
                    <Div style={$ticektTitle}>{tickets.data.title}</Div>

                    <Div>
                      {normalizeDate(tickets.data.created_at)} -{" "}
                      {tickets.data.responsible_user?.profile?.name} -{" "}
                      {tickets.data.responsible_user?.email}
                    </Div>
                  </Div>
                </Div>
              )}
              <Div style={switchState ? {...$ticketResponseFieldBlockWrapper, ...$switchOn} : {...$ticketResponseFieldBlockWrapper, ...$switchOff}}>
              <Div style={$ticketResponseFieldBlock}>
                  {auth.user.profile.avatar ? (
                    <Div>{renderAvatar(auth.user.profile.avatar)}</Div>
                  ) : (
                    <Div>{firstLetter(auth.user.profile.name)}</Div>
                  )}

                <FormControl sx={{ width: "100%" }}>
                  <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
                      image: { uploadCallback: uploadCallback },
                    }}
                    /* toolbarCustomButtons={[
                      <IconButton
                        onClick={() => {
                          const element = document.getElementById("image_response_input");

                          element.click();
                        }}
                      >
                        <Icon color={"action"}>image</Icon>
                      </IconButton>
                    ]} */
                  />
                </FormControl>
              </Div>
              <Div className="pl-[73px]">
                <SwitchTickets switchState={switchState} setSwitchState={throgleSwitch}/>
              </Div>
              </Div>

              {ticketsResponses.loading ? (
                <div>Carregando...</div>
              ) : (
                <Div style={$answerBlock}>
                  {ticketsResponses?.data?.data?.map((item, index) => (
                    <Div style={{...$answer, ...(item?.is_public ? $switchOn : $switchOff)}} key={index}>                            
                    {item?.user?.profile?.avatar ? (
                              <Div>{renderAvatar(item?.user?.profile?.avatar)}</Div>
                            ) : (
                              <Div>{firstLetter(item?.user?.profile?.name)}</Div>
                            )}                      
                            
                        <Div style={$answerRenderBlock}>
                        <Div style={$answerUserTitle}>
                          <span style={$userName}>
                            {item?.user?.profile?.name}
                            {' - '}{icons[item?.ticket_channel_id - 1]}
                            {item?.is_public === 0 &&" - Comentário interno"} 
                          </span>
                          <span style={$userName}>
                            {normalizeDate(item?.created_at)}
                          </span>
                        </Div>
                        {isHtml(item?.response) ? (
                          <div
                            dangerouslySetInnerHTML={{ __html: item?.response }}
                          />
                        ) : (
                          item?.response
                        )}
                      </Div>
                    </Div>
                  ))}
                </Div>
              )}
            </Stack>
          </Div>

          <Div style={$footerBlock}>
            <SplitButton loading={tickets.loading} setFieldValue={setFieldValue} />
          </Div>
        </Form>
      )}
    </Formik>
    </>)}</>
  );
};
const $form = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
};
const $leftBlockRequestInfo = {
  padding: "40px 20px",
  borderBottom: "solid 1px",
  borderColor: "#ccc",
};
const $answerRenderBlock = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  padding: 10,
};
const $userName = {
  fontSize: 14,
  fontWeight: 500,
};
const $answerUserTitle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};
const $answer = {
  padding: 20,
  borderTop: "solid 1px",
  borderColor: colors.borderColors,
  flexDirection: "row",
  display: "flex",
};

const $answerBlock = {
  borderTop: "solid 1px",
  borderColor: colors.borderColors,
};

const $ticketResponseFieldBlock = {
  padding: 20,
  width: "100%",
  display: "flex",
  flexDirection: "row",
  transition: "background-color 0.4s ease"

};
const $ticketResponseFieldBlockWrapper = {
  transition: "background-color 0.4s ease"

};

const $switchOn = {
  backgroundColor: "#fff",  
}

const $switchOff = {
  backgroundColor: "#fff6f6",  
}

const $titleTicketBlock = {
  display: "flex",
  flexDirection: "row",
  padding: 20,
  borderBottom: "solid 1px",
  borderColor: colors.borderColors,
};
const $ticektTitle = {
  fontSize: 20,
  fontWeight: 500,
};
const $headerBlock = {
  backgroundColor: colors.standardWhite,
  maxWidth: "100%",
  padding: 10,
  flexDirection: "row",
  display: "flex",
  borderTop: "solid 1px",
  borderBottom: "solid 1px",
  borderColor: colors.borderColors,
};
const $footerBlock = {
  backgroundColor: colors.standardWhite,
  maxWidth: "100%",
  padding: 10,
  flexDirection: "row",
  display: "flex",
  borderTop: "solid 1px",
  borderBottom: "solid 1px",
  borderColor: colors.borderColors,
  minHeight: "6vh",
  flexWrap: "wrap",
  justifyContent: "flex-end",
  alignContent: "center",
};

const $midBlock = {
  backgroundColor: "#fff",
  flex: 1,
  overflow: "scroll",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-track": {
    background: "red",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
  maxHeight: "77vh",
};

const $tagsTop = {
  border: "solid 1px ",
  borderColor: colors.borderColors,
  padding: "6px 12px",
  backgroundColor: colors.standardGray,
  display: "flex",
  alignContent: "center",
  flexWrap: "wrap",
  cursor: "pointer",

};
const $tagsTopLeft = {
  borderTopLeftRadius: "5px",
  borderBottomLeftRadius: "5px",
  borderRight: 0,
};
const $tagsTopRight = {
  borderTopRightRadius: "5px",
  borderBottomRightRadius: "5px",
  borderLeft: 0,
  backgroundColor: "#fff",
};

const $leftBlock = {
  backgroundColor: colors.standardGray,
  flex: 1,
  maxWidth: "25%",
};

const $container = {
  flex: 1,
  display: "flex",
  padding: 0,
};
export default TicketDetails;
