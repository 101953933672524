import { SET_ALERT } from "../../Alerts/constants";
import {
  GET_ISSUES_SUCCESS,
  GET_ISSUES_FAILURE,
  GET_ISSUES_REQUEST,
  DELETE_ISSUES_FAILURE,
  DELETE_ISSUES_REQUEST,
  DELETE_ISSUES_SUCCESS,
  POST_ISSUES_REQUEST,
  POST_ISSUES_FAILURE,
  POST_ISSUES_SUCCESS,
  PUT_ISSUES_REQUEST,
  PUT_ISSUES_FAILURE,
  PUT_ISSUES_SUCCESS,
} from "./constants";
import api from "app/services/config";

export const getIssues =
  (order, orderBy, status, page, searchKey, noPaginate) => async (dispatch, getState) => {
    dispatch({ type: GET_ISSUES_REQUEST });

    try {
      const { auth, search } = getState();
      const response = await api.get(`/product-models/issues`, {
        params: {
          orderBy: `${search.data.orderBy},${search.data.order}`,
          status: search.data.status,
          page: search.data.page,
          s: searchKey,
          noPaginate: noPaginate,
          product_model_id: search.data.category,
          

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_ISSUES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ISSUES_FAILURE,
        payload: error.message,
      });
    }
  };

export const deleteIssues = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_ISSUES_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.delete(`/product-models/issues/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    dispatch({
      type: DELETE_ISSUES_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Deletado com sucesso", variant: "success" }, 
    })
  } catch (error) {
    dispatch({
      type: DELETE_ISSUES_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao deletar", variant: "error" }, 
    })
  } finally {
    dispatch(getIssues("asc", "id"));
  }
};
export const createIssues =
  (data) => async (dispatch, getState) => {
    dispatch({ type: POST_ISSUES_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.post(
        `/product-models/issues`,
        {
          name: data.name,
          code: data.code,
          description: data.description,
          product_model_id: data.product_model_id,
          status: data.status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      dispatch({
        type: POST_ISSUES_SUCCESS,
        payload: response.data,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Criado com sucesso", variant: "success" }, 
      })
    } catch (error) {
      dispatch({
        type: POST_ISSUES_FAILURE,
        payload: error.message,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Erro ao criar", variant: "error" }, 
      })
    } finally {
      dispatch(getIssues("asc", "id"));
    }
  };

  export const editIssues =
  (data, id) => async (dispatch, getState) => {
    dispatch({ type: PUT_ISSUES_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.put(
        `/product-models/issues/${id}`,
        {
          name: data.name,
          code: data.code,
          description: data.description,
          product_model_id: data.product_model_id,
          status: data.status,

        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      dispatch({
        type: PUT_ISSUES_SUCCESS,
        payload: response.data,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Editado com sucesso", variant: "success" }, 
      })
    } catch (error) {
      dispatch({
        type: PUT_ISSUES_FAILURE,
        payload: error.message,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Erro ao editar", variant: "error" }, 
      })
    } finally {
      dispatch(getIssues("asc", "id"));
    }
  };
