import {
  REOPEN_SERVICE_ORDERS_REQUEST,
  REOPEN_SERVICE_ORDERS_FAILURE,
  REOPEN_SERVICE_ORDERS_SUCCESS,  
  CANCEL_SERVICE_ORDERS_FAILURE,
  CANCEL_SERVICE_ORDERS_REQUEST,
  CANCEL_SERVICE_ORDERS_SUCCESS,
  CLEAN_SERVICE_ORDERS_STORE,
  GET_SERVICE_ORDERS_REQUEST,
  GET_SERVICE_ORDERS_FAILURE,
  GET_SERVICE_ORDERS_SUCCESS,
  CREATE_SERVICE_ORDERS_REQUEST,
  CREATE_SERVICE_ORDERS_FAILURE,
  CREATE_SERVICE_ORDERS_SUCCESS, 
  
} from "./constants";
 
const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function serviceOrders(state = initialState, action) {
  switch (action.type) {
    case GET_SERVICE_ORDERS_SUCCESS:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null,
      };
      case GET_SERVICE_ORDERS_REQUEST:
      case REOPEN_SERVICE_ORDERS_REQUEST:
      case CREATE_SERVICE_ORDERS_REQUEST:
      case CANCEL_SERVICE_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      case GET_SERVICE_ORDERS_FAILURE:
      case CANCEL_SERVICE_ORDERS_FAILURE:
      case REOPEN_SERVICE_ORDERS_FAILURE:
      case CREATE_SERVICE_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };     
    case REOPEN_SERVICE_ORDERS_SUCCESS:
    case CREATE_SERVICE_ORDERS_SUCCESS:
    case CANCEL_SERVICE_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };   
    case CLEAN_SERVICE_ORDERS_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
