import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "./style";
import { Stack } from "@mui/material";
import DynamicViewGenerator from "app/components/DynamicViewGenerator/DynamicViewGenerator";

import { CLEAN_READONLY_STORE } from "app/redux/modules/Users/Readonly/constants";
import { asyncCompaniesActions, asyncReadonlyActions, asyncGetEmail } from "app/redux/reducers";
import { DATA_TYPE_CHIP, DATA_TYPE_DATE, EMAIL_INPUT, MASKED_INPUT, PASSWORD_INPUT, PHOTO_UPLOAD_INPUT, STATUS_INPUT, TEXT_INPUT, UNIT_INPUT } from "app/components/DynamicViewGenerator/constants";
import * as Yup from "yup";
import api from "app/services/config";

const Readonly = () => {
  const dispatch = useDispatch();
  const { readonly, companies, auth } = useSelector((state) => state);
  const [newSelectOptions, setNewSelectOptions] = React.useState([]);


  const startPage = async () => {
    await dispatch(asyncReadonlyActions.get("asc", "id"));
    await dispatch(asyncCompaniesActions.getCompanies("asc", "id","1",""," ",true));

  }
  useEffect(() => {
    startPage();
    return () => {
      dispatch({ type: CLEAN_READONLY_STORE });
    }
  
  }, [dispatch]);
  useEffect(() => {
    if (companies.data) {
      setNewSelectOptions(
        companies.data.data &&
        companies.data.data.map(({ id, name }) => ({ id, name }))
      );
    }
  }, [companies]);

  const dataLabels = [
    {
      avatar: "Avatar",
      name: "Nome",
      last_name: "Último Nome",
      cpf: "Cpf",
      phone: "Telefone",
      email: "E-mail",
      //id: "Código",
      created_at: "Data criação",
      status: "Status"
    },
  ];

  const dataType = [
    {
      avatar: {
        parent: "profile",
        name: "avatar"
      },
      id: "string",
      name: {
        parent: "profile",
        name: "name"
      },
      cpf: {
        parent: "profile",
        name: "cpf"
      },
      phone: {
        parent: "profile",
        name: "phone"
      },
      email: "string",
      last_name: {
        parent: "profile",
        name: "last_name"
      }, 
      created_at: DATA_TYPE_DATE,
      status: DATA_TYPE_CHIP

    },
  ];

  const formFieldsType = [
 
    {
      type: PHOTO_UPLOAD_INPUT,
      value: "avatar",
      label: "Avatar",
      required: false,
      action: "",
      parent: "profile",
    },
    {
      type: TEXT_INPUT,
      value: "name",
      label: "Nome",
      required: true,
      action: "",
      parent: "profile",
      validation: Yup.string().required("Obrigatório")
    },  
    {
      type: TEXT_INPUT,
      value: "last_name",
      label: "Último Nome",
      required: true,
      action: "",
      parent: "profile",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: MASKED_INPUT,
      value: "cpf",
      label: "Cpf",
      required: true,
      mask: "999.999.999-99",
      action: "",
      parent: "profile",
      validation: Yup.string().transform(value => value.replace(/[^\d]/g, '')).min(11, "Cpf inválido").required("Obrigatório")
    },
    {
      type: EMAIL_INPUT,
      value: "email",
      label: "E-mail",
      required: true,
      action: "",
      parent: "",
      validation: Yup.string()
      .email("Email inválido")
      .required("Obrigatório")
      .test('', 'E-mail já utilizado', async (value) => {
        if (value) {
          try {
            const response = await api.get(`users/has-email?email=${value}`, {
              headers: {
                Authorization: `Bearer ${auth.token}`
              }
            })
            return !response.data;
          } catch (error) {
            return true;
          }
        }
      })     
    },
    {
      type: "select",
      value: "company_id",
      label: "Empresa",
      options: newSelectOptions,
      required: true,
      action: "",
      parent: "external_user",
      child: "company",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: UNIT_INPUT,
      value: "unit_id",
      label: "Unidade",
      required: true,
      action: "",
      parent: "",
      validation: Yup.string().required("Obrigatório")

    },
    {
      type: MASKED_INPUT,
      value: "phone",
      label: "Telefone",
      required: true,
      mask: "(99) 9 9999-9999",
      action: "",
      parent: "profile",
      validation: Yup.string().transform(value => value.replace(/[^\d]/g, '')).min(11, "Número inválido").required("Obrigatório")

    },

    {
      type: PASSWORD_INPUT,
      value: "password",
      label: "Senha", 
      required: true,
      action: "",
      parent: "",
      validation: Yup.string().required("Obrigatório").min(6, "Insira o mínimo de 6 caracteres")

    },
    {
      type: STATUS_INPUT,
      value: "status",
      label: "Status",
      required: true,
      action: "",
      parent: "",
      validation: Yup.string().required("Obrigatório")

    },
  ];

  const pageConfig = {
    title: "Usuários Somente Leitura",
    newText: "Novo usuário",
    searchText: "Pesquisar",
    data: readonly.data,
    loading: readonly.loading,
    actions: {
      delete: asyncReadonlyActions.delete,
      edit: asyncReadonlyActions.edit,
      get: asyncReadonlyActions.get,
      search: asyncReadonlyActions.get,
      create: asyncReadonlyActions.create,
      clean: { type: CLEAN_READONLY_STORE },
    },
    createDataType: formFieldsType,
  };

  return (
    <Content>
      {
        <Stack style={{ marginTop: 10 }}>
          <DynamicViewGenerator
            pageConfig={pageConfig}
            route={"modelos"}
            type={dataType}
            labels={dataLabels}
          />
        </Stack>
      }
    </Content>
  );
};

export default Readonly;
