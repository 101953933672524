import React, {Suspense, useEffect} from "react";
import {Provider, useDispatch, useSelector} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import AppRoutes from "./AppRoutes";
import configureStore, {history} from './redux/store';
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import {SnackbarProvider} from "notistack";
import "./services/apis";
import AppProvider from "./AppProvider";
import {config} from "./config/main";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import Div from "@jumbo/shared/Div";
import {CircularProgress} from "@mui/material";
import { PersistGate } from 'redux-persist/integration/react'
import SystemMessage from "./components/SystemMessage";
import { asuncNotificationsActions } from "./redux/reducers";
import FaviconNotification from "./components/FaviconNotification";
import OneSignal from 'react-onesignal';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});




const { store, persistor } = configureStore();

function App() {
  

      
    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter history={history}>
                        <AppProvider>
                            <JumboApp activeLayout={config.activeLayout}>
                                <JumboTheme init={config.theme}>
                                    <JumboRTL>
                                        <JumboDialogProvider>
                                            <JumboDialog/>
                                            <SnackbarProvider
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                maxSnack={3}>
                                                <SystemMessage />
                                                <FaviconNotification />
                                                <AppLayout>
                                                    <Suspense
                                                        fallback={
                                                            <Div
                                                                sx={{
                                                                    display: 'flex',
                                                                    minWidth: 0,
                                                                    alignItems: 'center',
                                                                    alignContent: 'center',
                                                                    height: '100%',
                                                                }}
                                                            >
                                                                <CircularProgress sx={{m: '-40px auto 0'}}/>
                                                            </Div>
                                                        }
                                                    >
                                                        <AppRoutes/>
                                                    </Suspense>
                                                </AppLayout>

                                            </SnackbarProvider>
                                        </JumboDialogProvider>
                                    </JumboRTL>
                                </JumboTheme>
                            </JumboApp>
                        </AppProvider>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </QueryClientProvider>
    );
}

export default App;
