import baseAxios from "axios";

let apiHost = "";

switch (process.env.REACT_APP_ENV) {
  case "localhost": {
    apiHost = "http://localhost:9006";
    break;
  }
  case "development": {
    apiHost = "https://api.perfil.focalweb.com.br/";
    break;
  }
  case "production": {
    apiHost = "https://api.perfil.focalweb.com.br/";
    break;
  }
}

const axios = baseAxios.create({
    baseURL: apiHost,
    headers: {
        'Content-Type': 'application/json'
    }
});
//todo: define interceptors and other configuration like baseURL, headers etc. here
export default axios;