import axios from "axios";
import {
  GET_PRODUCT_MODEL_SUCCESS,
  GET_PRODUCT_MODEL_FAILURE,
  GET_PRODUCT_MODEL_REQUEST,
  DELETE_PRODUCT_MODEL_FAILURE,
  DELETE_PRODUCT_MODEL_REQUEST,
  DELETE_PRODUCT_MODEL_SUCCESS,
  POST_PRODUCT_MODEL_REQUEST,
  POST_PRODUCT_MODEL_FAILURE,
  POST_PRODUCT_MODEL_SUCCESS,
  PUT_PRODUCT_MODEL_REQUEST,
  PUT_PRODUCT_MODEL_FAILURE,
  PUT_PRODUCT_MODEL_SUCCESS,
} from "./constants";
import api from "app/services/config";
import { SET_ALERT } from "../../Alerts/constants";

export const getProductModels =
  (order, orderBy, status, page, searchKey, noPaginate, hasProductModelsParts) => async (dispatch, getState) => {
    dispatch({ type: GET_PRODUCT_MODEL_REQUEST });

    try {
      const { auth, search } = getState();
      const response = await api.get(`/product-models`, {
        params: {
          orderBy: `${search.data.orderBy},${search.data.order}`,
          status: search.data.status,
          page: search.data.page,
          s: searchKey,
          noPaginate: noPaginate,
          product_id: search.data.category,
          hasProductModelsParts: hasProductModelsParts

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_PRODUCT_MODEL_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PRODUCT_MODEL_FAILURE,
        payload: error.message,
      });
    }
  };

export const deleteProductModel = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_PRODUCT_MODEL_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.delete(`/product-models/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    dispatch({
      type: DELETE_PRODUCT_MODEL_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Deletado com sucesso", variant: "success" }, 
    })

  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_MODEL_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao deletar", variant: "error" }, 
    })
  } finally {
    dispatch(getProductModels("asc", "id"));
  }
};


export const createProductModel =
  (data) => async (dispatch, getState) => {
    dispatch({ type: POST_PRODUCT_MODEL_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.post(
        `/product-models`,
        {
          name: data.name,
          description: data.description,
          status: data.status,
          product_id: data.product_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      dispatch({
        type: POST_PRODUCT_MODEL_SUCCESS,
        payload: response.data,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Criado com sucesso", variant: "success" }, 
      })
    } catch (error) {
      dispatch({
        type: POST_PRODUCT_MODEL_FAILURE,
        payload: error.message,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Erro ao criar", variant: "error" }, 
      })
    } finally {
      dispatch(getProductModels("asc", "id"));
    }
  };

  export const editProductModel =
  (data, id) => async (dispatch, getState) => {
    dispatch({ type: PUT_PRODUCT_MODEL_REQUEST });
   
    try {
      const { auth } = getState();
      const response = await api.put(
        `/product-models/${id}`,
        {
          name: data.name,
          description: data.description,
          status: data.status,
          product_id: data.product_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      dispatch({
        type: PUT_PRODUCT_MODEL_SUCCESS,
        payload: response.data,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Editado com sucesso", variant: "success" }, 
      })
    } catch (error) {
      dispatch({
        type: PUT_PRODUCT_MODEL_FAILURE,
        payload: error.message,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Erro ao editar", variant: "error" }, 
      })
    } finally {
      dispatch(getProductModels("asc", "id"));
    }
  };
