import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  Button,
  CardHeader,
  Divider,
  FormControl,
  MenuItem,
  Stack,
  TextField,
  Autocomplete,
  CircularProgress,
  Card,
  Select,
  Chip,
  Typography,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import { Formik, Form, Field, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { CloseOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import { appTheme } from "app/themes/app/theme";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ReactInputMask from "react-input-mask";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Geocode from "react-geocode";
import { ADDRESS_INPUT, CITY_INPUT, EMAIL_INPUT, MASKED_INPUT, PASSWORD_INPUT, PHOTO_UPLOAD_INPUT, SELECT_AUTOCOMPLETE_INPUT, STATUS_INPUT, TEXT_INPUT, UF_INPUT, UNIT_INPUT } from "./constants";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AvatarEditor from 'react-avatar-editor'
import Dropzone from "react-dropzone";
import { ref } from "yup";
import { use } from "i18next";
import { debounce } from 'lodash';
import { asyncSearchActions, asyncUnitsActions } from "app/redux/reducers";
import { CLEAN_CUSTOMERS_STORE } from "app/redux/modules/Users/Customers/constants";
import { CLEAN_UNITS_STORE } from "app/redux/modules/Customers/Units/constants";
import * as Yup from "yup";
import { CLEAN_CITIES_STORE } from "app/redux/modules/Cities/constants";
import { CLEAN_STATES_STORE } from "app/redux/modules/States/constants";
import { useLocation } from "react-router-dom";

/** 
@param {Object} props 
@param {boolean} props.open - Set modal to visible.
@param {VoidFunction} props.handleClose - Closes de modal 
@param {Array} props.pageConfig - The page configuration object
@param {Object} props.item - Id of the item to be edited
*/

const SimpleModalEditor = (props) => {
  const dispatch = useDispatch();
  const { units, states, cities } = useSelector(
    (state) => state
  );
  const { open, handleClose, item, pageConfig } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const [avatar, setAvatar] = useState({image: null,
  upload: null});
  const handleDrop = (avatar) => {
    setAvatar({image: avatar[0]});
  }
  const [companyId, setCompanyId] = useState();
  const [selectedState, setSelectedState] = useState(null);

  const isObject = (value) => {
    return Object.prototype.toString.call(value) === "[object Object]";
  };
  Geocode.setApiKey(process.env.REACT_APP_GEOCODE_KEY);
  const location = useLocation();

  const initialValues = pageConfig.createDataType.reduce((acc, field) => {
    return {
      ...acc,
      [field.value]: item
        ? isObject(item)
          ? field.parent &&
            item[field.parent] &&
            item[field.parent][field.value]
            ? item[field.parent][field.value]
            : item[field.value]
          : item[field.value]
        : "",
    };
  }, {});

  const SchemaObject = Object.fromEntries(pageConfig.createDataType.map((page)=>[page.value, 
    item ?
     page.type === PASSWORD_INPUT ? "" 
      : page.type === EMAIL_INPUT ? "" 
        : page.validation 
          : page.validation]))

  const validationSchema = Yup.object().shape(SchemaObject)

  const selectStatusOptions = [
    {
      id: 1,
      label: "Ativo",
      name: "ativo",
    },
    {
      id: 0,
      label: "Desativado",
      name: "desativado",
    },
  ];
  useEffect(() => {
    console.log('edited item', item)
   
    if (location.pathname.includes('/usuarios')){
      open && dispatch(asyncUnitsActions.getUnitsFromCompany(item.external_user.company_id))
    }
  }, [item])

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const handleSubmit = (values) => {
    if (item) {
      dispatch(pageConfig.actions.edit(values, item.id));   
      setAvatar({image: null});
      handleClose();

      return;
    }
    dispatch(pageConfig.actions.create(values))
    setAvatar({image: null});
    handleClose();
    return;

  };
  const [editor, setEditor] = useState(null)
  const setEditorRef = (editor) => (setEditor(editor))
  
  // useEffect(() => {
  //   if (location.pathname === '/usuarios'){

  //   if(companyId){
  //     open && dispatch(asyncUnitsActions.getUnitsFromCompany(companyId))
  //   }
  // }
  //   return () => {
  //     dispatch({type: CLEAN_UNITS_STORE})
  //   }

  // }, [companyId, item])

  if(!open){
    return(<></>)
  }

  return (
    <div>
      <Modal style={{ overflowY: 'scroll' }} open={open} onClose={handleClose}>        
        <Card style={$modalBox} sx={{ p: 2, width: 400, height: 400 }}>
          <Div className="flex flex-row justify-between items-center mb-2 scroll-auto">
            {console.log("see item", item)}
            <Typography id="transition-modal-title" variant="h4" component="h2">
              {/* {console.log("tipos .>>>>", types)} */}
              {item
                ? `Editar ${pageConfig.title} - #${item.id}`
                : `Adicionar ${pageConfig.title}`}
            </Typography>

            <Button color="error" onClick={() => handleClose()}>
              <CloseOutlined />
            </Button>
          </Div>

          <Divider sx={{ mb: 2 }} />

          <Stack flexDirection="row">
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              style={{ display: "flex", flex: 1 }}
            >
              {({ values, handleChange, setFieldValue, resetForm, errors, touched, handleBlur }) => (
                <Form style={{ display: "flex", flex: 1 }}>
                  <Div
                    sx={{ display: "flex", flex: 1, flexDirection: "column" }}
                  >
                    {console.log("Valores do form", values)}
                    {pageConfig.createDataType.map((field) => {
                      switch (field.type) {
                        case PHOTO_UPLOAD_INPUT:
                          return(
                            <FormControl style={{display: "flex", alignContent: "center", flexWrap: "wrap"}} sx={{ mb: 2 }}>
                                    <Dropzone
                                      onDrop={handleDrop}
                                      noClick={avatar.image && true}
                                      noKeyboard
                                      style={{ width: '150px', height: '150px' }}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                          {avatar.image ? (
                                            <AvatarEditor 
                                            ref={setEditorRef}
                                            onImageReady={() => setFieldValue("avatar", avatar.image)}
                                            onMouseUp={() =>{ 
                                              const canvas = editor.getImageScaledToCanvas()
                                              canvas.toBlob((blob) => {
                                                const file = new File([blob], avatar.image.name, { type: avatar.image.type });
                                                   setFieldValue("avatar",file )
                                              }, avatar.type);
                                            }}
                                            width={150} 
                                            height={150} 
                                            borderRadius={500}
                                            image={avatar.image} />
                                            ):(

                                              <Div
                                              sx={{
                                                mr: { xs: 0, md: 2 },
                                                width: 150,
                                                height: 150,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                bgcolor: "#ccc",
                                                borderRadius: "500px",
                                                p: (theme) => theme.spacing(1, 2),
                                                px: 1,
                                                mb: { xs: 2, md: 0 },
                                                overflow: "hidden",
                                              }}
                                            >
                                              {initialValues[field.value] ? (
                                                <img src={`${process.env.REACT_APP_PHOTO_PATH}${initialValues[field.value]}`} width={150} height={150}/>
                                              ):(
                                                  <Typography 
                                                  variant={"h4"}
                                                  color={"#fff"}
                                                  mb={0}
                                                  align="center"
                                                  >           
                                                      Selecionar Foto
                                                  </Typography>  

                                              )}
                                   
                                              </Div>
                                            )}
                                          <input
                                          
                                          id={field.value}
                                          name={field.value}
                                          label={field.label}
                                          {...getInputProps()} 
                                          InputProps={{
                                            autoComplete: "off",
                                          }}
                                          />
                                          
                                        </div>
                                       
                                      )}
                                    </Dropzone>
                          </FormControl>
                          );
                        case TEXT_INPUT:

                          if(item){
                            if(field.createOnly){
                            return(<></>)
                            }
                          }
                          return (
                            <FormControl sx={{ mb: 2 }}>
                              <TextField
                                fullWidth
                                defaultValue={initialValues[field.value]}
                                style={{ zIndex: 0 }}
                                id={field.value}
                                name={field.value}
                                label={field.required === true ? `${field.label}*` : field.label}
                                InputProps={{
                                  autoComplete: "off",
                                }}
                                error={touched[field.value] && Boolean(errors[field.value])}
                                helperText={touched[field.value] && errors[field.value]}
                                onChange={handleChange}                                
                              ></TextField>
                            </FormControl>
                          );
                          case EMAIL_INPUT:
                            return (
                              <FormControl sx={{ mb: 2 }}>
                                <TextField
                                  fullWidth
                                  defaultValue={initialValues[field.value]}
                                  style={{ zIndex: 0 }}
                                  id={field.value}
                                  name={field.value}
                                  label={field.required === true ? `${field.label}*` : field.label}
                                  InputProps={{
                                    autoComplete: "off",
                                  }}
                                  onBlur={handleBlur}
                                  error={touched[field.value] && Boolean(errors[field.value])}
                                  helperText={touched[field.value] && errors[field.value]}
                                  onChange={handleChange}                                
                                ></TextField>
                              </FormControl>
                            );  
                        case SELECT_AUTOCOMPLETE_INPUT:
                          return (
                            <FormControl sx={{ mb: 2 }}>
                              <Autocomplete
                                // disablePortal
                                filterSelectedOptions
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                defaultValue={field?.options?.find(
                                  (option) =>
                                    option.id === initialValues[field.value]
                                )}
                                options={field.options}
                                fullWidth
                                InputProps={{
                                  autoComplete: "off",
                                }}
                                onChange={(event, value) =>{
                                  if(field.value == "company_id"){
                                    setCompanyId(value.id)
                                    
                                  }
                                  setFieldValue(
                                    field.value,
                                    value !== null
                                      ? value.id
                                      : initialValues[field.value]
                                  )
                                }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    id={field.value}
                                    name={field.value}
                                    label={field.required === true ? `${field.label}*` : field.label}
                                    error={touched[field.value] && Boolean(errors[field.value])}
                                    helperText={touched[field.value] && errors[field.value]}
                                  />
                                )}
                              />
                            </FormControl>
                          );                       
                        case UNIT_INPUT:

                          return (
                            <FormControl sx={{ mb: 2 }}>
                              
                              <TextField
                                fullWidth
                                select
                                label={field.required === true ? `${field.label}*` : field.label}
                                id={field.value}
                                name={field.value}
                                error={touched[field.value] && Boolean(errors[field.value])}
                                helperText={touched[field.value] && errors[field.value]}
                                disabled={units?.data?.data?.length == 0}
                                value={values[field.value]}
                                onChange={(event) => {
                                  setFieldValue(
                                    field.value,
                                    event.target.value !== null
                                      ? event.target.value
                                      : initialValues[field.value]
                                  )
                                }
                                }
                              >
                                {
                                  units?.data?.data?.map(property => <MenuItem value={property.id}>{property.address}</MenuItem>)
                                }                                     
                              </TextField>
                            </FormControl>
                          )
                        case PASSWORD_INPUT:
                          return (
                            <FormControl sx={{ mb: 2 }} >
                              <TextField
                                id={field.value}                              
                                name={field.value}
                                error={touched[field.value] && Boolean(errors[field.value])}
                                helperText={touched[field.value] && errors[field.value]}
                                type={showPassword ? "text" : "password"}
                                onChange={handleChange}
                                InputProps={{
                                  autoComplete: "off",
                                  endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                  ),
                                  }}
                                label={field.required === true ? `${field.label}*` : field.label}                           
                              />
                            </FormControl>
                          );
                        case MASKED_INPUT:
                          return (
                            <FormControl sx={{ mb: 2 }}>
                              <ReactInputMask
                                mask={field.mask}
                                fullWidth
                                defaultValue={initialValues[field.value]}
                                style={{ zIndex: 0 }}
                                id={field.value}
                                name={field.value}
                                label={field.label}
                                InputProps={{
                                  autoComplete: "off",
                                }}
                                onChange={handleChange}
                              >
                                {() => (
                                  <TextField
                                    label={field.required === true ? `${field.label}*` : field.label}
                                    id={field.value}
                                    name={field.value}
                                    error={touched[field.value] && Boolean(errors[field.value])}
                                    helperText={touched[field.value] && errors[field.value]}
                                  />
                                )}
                              </ReactInputMask>
                            </FormControl>
                          );
                        case ADDRESS_INPUT:
                          
                          if(item){
                            if(field.createOnly){
                            return(<></>)
                            }
                          }

                          return (
                          <FormControl sx={{ mb: 2 }}>
                            
                            <GooglePlacesAutocomplete
                              style={{ zIndex: 199 }}
                              apiKey={process.env.REACT_APP_GEOCODE_KEY}
                              fullWidth
                              label={field.required === true ? `${field.label}*` : field.label}
                              id={field.value}
                              name={field.value}   
                              onChange={handleChange}                      
                              selectProps={{
                                id: field.value,
                                defaultValue: { label: values[field.value] ? values[field.value]  : "Endereço" , value: values[field.value] }, 
                                placeholder: field.required === true ? `${field.label}*` : field.label,
                                onChange: (endereco) => {
                                  Geocode.fromAddress(endereco.label)
                                    .then(
                                      (response) => {
                                      

                                          //  console.log('objeto do resultado end',response.results[0])
                                           setFieldValue(field.value, response.results[0])
                                      },
                                      (error) => {
                                        console.error(error);
                                      }
                                    )
                                    .catch((error) => {
                                      console.log(error);
                                    });
                                },
                              }}
                              apiOptions={{ language: "pt-br", region: "br" }}
                              inputProps={{ autoComplete: "off" }}
                            >{() =>
                            <TextField
                              fullWidth       
                              label={field.required === true ? `${field.label}*` : field.label}
                              id={field.value}
                              name={field.value} 
                              error={touched[field.value] && Boolean(errors[field.value])}
                              helperText={touched[field.value] && errors[field.value]}                      
                          
                            />}
                            </GooglePlacesAutocomplete>
                          </FormControl>
                          )
                        case STATUS_INPUT:
                          return (
                            <FormControl sx={{ mb: 2 }}>
                              <Autocomplete
                                disablePortal
                                options={selectStatusOptions}
                                fullWidth
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                defaultValue={selectStatusOptions.find(
                                  (option) =>
                                    option.id === initialValues[field.value]
                                )}
                                onChange={(event, value) =>
                                  setFieldValue(
                                    "status",
                                    value !== null
                                      ? value.id
                                      : initialValues.status
                                  )
                                }
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    id="status"
                                    name="status"
                                    label={field.required === true ? `${field.label}*` : field.label}
                                    error={touched[field.value] && Boolean(errors[field.value])}
                                    helperText={touched[field.value] && errors[field.value]}
                                  />
                                  )}
                                  />
                            </FormControl>
                          );
                          
                        default:
                          return null;
                      }
                      
                    })}
                    <Divider sx={{ mb: 2 }} />

                    <Stack flexDirection="row" justifyContent={"flex-end"}>
                      <Button
                        variant="contained"
                        color="error"
                        sx={{ mr: 2 }}
                        onClick={() => {
                          resetForm();
                          handleClose();
                        }}
                      >
                        Cancelar
                      </Button>
                      <Button type="submit" variant="contained" color="success">
                        Salvar
                      </Button>
                    </Stack>
                  </Div>
                </Form>
              )}
            </Formik>
          </Stack>
        </Card>
      </Modal>
    </div>
  );
};

const $modalBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
  height: "auto",
};

const $boxLateral = {
  width: "20%",
  border: "solid 1px #ccc",
  margin: "35px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export default SimpleModalEditor;
