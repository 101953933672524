import React from 'react';
import {useDropzone} from "react-dropzone";
import {Typography} from "@mui/material";
import DndWrapper from "./DndWrapper";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import JumboCardQuick from '@jumbo/components/JumboCardQuick/JumboCardQuick';
import { fromImage } from 'imtool';
import Compressor from 'compressorjs';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 300,
    height: 260,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

const img = {
    display: 'block',
    width: '100%',
    height: '100%',
};

const DzPreviews = ({ selectFiles, image, video }) => {

    const [files, setFiles] = React.useState([]);
    const [compress, setCompress] = React.useState(null);
    const [compressedImages, setCompressedImages] = React.useState([]);

    React.useEffect(() => {
        if(compress) {
            setCompressedImages(prev => [...prev, compress]);
        }
    }, [compress]);

    React.useEffect(() => {
        selectFiles(compressedImages);
    }, [compressedImages]);

    React.useEffect(() => {
        setCompressedImages([]);
        files.forEach(async file => {
            console.log(file);

            new Compressor(file, {
                quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
                maxHeight: 600,
                maxWidth: 600,     
                success: (compressedResult) => { 
                    const blobToFile = new File(
                        [compressedResult],
                        compressedResult.name,
                        {
                            type: compressedResult.type,
                            lastModified: compressedResult.lastModified
                        }
                    );

                    console.log("BLOB TO FILE > ", blobToFile);

                    setCompress(blobToFile);
                },
            });
        });

        setCompress(null);
    }, [files]);

    const {getRootProps, getInputProps} = useDropzone({
        accept: image ? ['image/*'] : ['video/*'],
        onDrop: acceptedFiles => {
            setFiles(
                prev => [
                    ...prev,
                    ...acceptedFiles.map(file =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        }),
                    ),
                ]
            );
        },
    });

    const thumbs = files.map((file, index) => (
            <div style={thumb} key={file.name} onDoubleClick={() => {
                const res = window.confirm("Deseja remover essa mídia?");
                if(res) {
                    setFiles(prev => prev.filter((_, i) => i != index));
                }
            }}>
                <div style={thumbInner}>
                    {
                        file.type.includes("image") ? <img src={file.preview} style={img} alt=""/>
                        : <video src={file.preview} controls style={img} alt="" />
                    }
                </div>
            </div>
        ));

    React.useEffect(
        () => () => {
            files.forEach(file => URL.revokeObjectURL(file.preview));
        },
        [files],
    );

    return (
        <Div sx={{ flex: 1}}>
            <DndWrapper>
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <Typography sx={{ cursor: "pointer" }} variant={"body1"}>Clique ou solte {image ? "as imagens" : "os vídeos"} aqui!</Typography>
                </div>
            </DndWrapper>
            <aside style={thumbsContainer}>{thumbs}</aside>
        </Div>
    );
};

export default DzPreviews;
