import { asyncNotificationsActions } from "app/redux/reducers";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Favicon from "react-favicon";

const FaviconNotification = () => {
  const { notifications, tickets } = useSelector((state) => state);

  const dispatch = useDispatch();
  const isPWA = () => {
    return (
      (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) ||
      window.navigator.standalone === true
    );
  };
  const checkNotifications = () => {
    dispatch(asyncNotificationsActions.get());
    if ('setAppBadge' in navigator) {
        navigator.setAppBadge(notifications.data.length);
      }
  }
  React.useEffect(() => {
    checkNotifications()

  }, [tickets]);

  React.useEffect(() => {
    
    checkNotifications()
    if (isPWA()) {
      console.log('Running as PWA');
    } else {
      console.log('Running in web browser');
    }
  }, []);



  
  const checkBadge = () => {
    if(isPWA()) {
        return (<></>)
    }
    return (<Favicon
            url={`${process.env.PUBLIC_URL}/favicon.ico`}
            alertCount={notifications.data.length}
          />)
        
    } 
  

  
  return (
    <>
    {checkBadge()}
    </>
  );
};

export default FaviconNotification;
