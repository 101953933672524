import moment from "moment";
import { config } from "../config/main";
import { Avatar, Typography } from "@mui/material";
import { appTheme } from "app/themes/app/theme";
import Div from "@jumbo/shared/Div";
import * as Yup from "yup";
import api from "app/services/config";
import { useSelector } from "react-redux";

export const getAssetPath = (url, size) => {
  if (config.useImagePlaceHolder) {
    return `https://via.placeholder.com/${size}.png`;
  }

  return url;
};

export const normalizeDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

export const firstLetter = (word) => {
  return (
    <Div
      sx={{
        mr: { xs: 0, md: 2 },
        width: { xs: 30, md: 45 },
        height: { xs: 30, md: 45 },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: appTheme.palette.red100,
        borderRadius: "50px",
        p: (theme) => theme.spacing(1, 2),
        px: 1,
        mb: { xs: 2, md: 0 },
      }}
    >
      <Typography variant={"h6"} color={"#fff"} mb={0}>
        {word ? word.charAt(0).toUpperCase() : "N/A"}
      </Typography>
    </Div>
  );
};


export const renderAvatar = (uri) => {

  const img = `${process.env.REACT_APP_PHOTO_PATH}${uri}`

  return (
    <Avatar src={img} 
    sx={{ width: 55, height: 55 }}
    
    />


  )

};
