import { Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const TicketsSkeleton = () => {
    return (        
        <Stack id="stack-usuario-skeleton" spacing={1} marginTop={1}>
            <Skeleton variant="rectangular" style={{ height: "1600px", width: "760", borderRadius: 10 }} />
        </Stack>
    )
};

export default TicketsSkeleton;