import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import contactsApp from "./contactsApp";
import auth from "./auth";
import family from "../modules/Products/families/reducer";
import {
  getProductFamilies,
  deleteProductFamilies,
  editProductFamilies,
  createProductFamilies,
} from "../modules/Products/families/actions";

import {
  deleteProductModel,
  getProductModels,
  editProductModel,
  createProductModel,
  updateProductModel,
} from "../modules/Products/productModel/actions";

import productModels from "../modules/Products/productModel/reducer";

import {
  getProduct,
  deleteProduct,
  editProduct,
  createProduct,
} from "../modules/Products/product/actions";

import {
  getParts,
  deleteParts,
  editParts,
  createParts,
} from "../modules/Products/parts/actions";

import {
  getCustomers,
  deleteCustomers,
  editCustomers,
  createCustomers,
} from "../modules/Users/Customers/actions";

import {
  getCompanies,
  deleteCompanies,
  editCompanies,
  createCompanies,
  getCompaniesTypes
} from "../modules/Customers/Companies/actions";
import {
  getUnits,
  deleteUnits,
  editUnits,
  createUnits,  
  getUnitsFromCompany,
} from "../modules/Customers/Units/actions";

import { 
  getProblemTypes,
  deleteProblemTypes,
  editProblemTypes,
  createProblemTypes
} from "../modules/Setup/ProblemTypes/actions"

import { 
  getMacros,
  deleteMacros,
  editMacros,
  createMacros
} from "../modules/Setup/Macros/actions"

import { 
  companies, 
  companiesTypes
} from "../modules/Customers/Companies/reducer";

import product from "../modules/Products/product/reducer";
import productFamilies from "../modules/Products/families/reducer";
import customers from "../modules/Users/Customers/reducer";
import units from "../modules/Customers/Units/reducer";
import parts from "../modules/Products/parts/reducer";
import problemTypes from "../modules/Setup/ProblemTypes/reducer";
import { createResolutionCategories, deleteResolutionCategories, editResolutionCategories, getResolutionCategories } from "../modules/Setup/ResolutionCategories/actions";
import resolutionCategories from "../modules/Setup/ResolutionCategories/reducer";
import { createAdministrators, deleteAdministrators, editAdministrators, getAdministrators } from "../modules/Users/Administrators/actions";
import { createSac, deleteSac, editSac, getSac } from "../modules/Users/Sac/actions";
import { createTechnicians, deleteTechnicians, editTechnicians, getTechnicians } from "../modules/Users/Technicians/actions";
import administrators from "../modules/Users/Administrators/reducer";
import sac from "../modules/Users/Sac/reducer";
import technicians from "../modules/Users/Technicians/reducer";
import { uploadPhoto } from "../modules/Users/PhotoUploads/actions";
import photo from "../modules/Users/PhotoUploads/reducer";
import { createTaskTypes, deleteTaskTypes, editTaskTypes, getTaskTypes } from "../modules/Setup/TaskTypes/actions";
import taskTypes from "../modules/Setup/TaskTypes/reducer";
import serviceOrders from "../modules/ServiceOrders/reducer";
import { cancelServiceOrder, createServiceOrder, editServiceOrder, getServiceOrder, reopenServiceOrder } from "../modules/ServiceOrders/actions";
import search from "../modules/Search/reducer";
import { getProductModelsFilter } from "../modules/Search/Filters/actions";
import filterProductModel from "../modules/Search/Filters/reducer";
import { createTickets, deleteTickets, editTickets, getSingleTicket, getTickets, getTicketsFromCompany } from "../modules/Tickets/actions";
import tickets from "../modules/Tickets/reducer";
import { createTicketsCategories, deleteTicketsCategories, editTicketsCategories, getTicketsCategories } from "../modules/TicketsCategories/actions";
import ticketsCategories from "../modules/TicketsCategories/reducer";
import { getTicketsResponses, postTicketsResponse } from "../modules/TicketsResponse/actions";
import ticketsResponses from "../modules/TicketsResponse/reducer";
import ticketsCC from "../modules/Tickets/Cc/reducer";
import { getTicketsCcUsers } from "../modules/Tickets/Cc/actions";
import macros from "../modules/Setup/Macros/reducer";
import alerts from "../modules/Alerts/reducer";
import states from "../modules/States/reducer";
import cities from "../modules/Cities/reducer";
import { getStates } from "../modules/States/actions";
import { getCities } from "../modules/Cities/actions";
import issues from "../modules/Products/issues/reducer";
import solutions from "../modules/Products/solutions/reducer";
import reseller from "../modules/Users/Resellers/reducer";
import { createIssues, deleteIssues, editIssues, getIssues } from "../modules/Products/issues/actions";
import { createSolutions, deleteSolutions, editSolutions, getSolutions } from "../modules/Products/solutions/actions";
import email from "../modules/E-mail/reducer";
import { getEmail } from "../modules/E-mail/actions";
import { getTicketsNotifications, postTicketNotifications } from "../modules/Notifications/actions";
import notifications from "../modules/Notifications/reducer";
import { createResellers, deleteResellers, editResellers, getResellers } from "../modules/Users/Resellers/actions";
import readonly from "../modules/Users/Readonly/reducer";
import { createReadonly, deleteReadonly, editReadonly, getReadonly } from "../modules/Users/Readonly/actions";

const exportReducers = (history) => {
  return combineReducers({
    router: connectRouter(history),
    contactsApp: contactsApp,
    auth: auth,
    family: family,
    productModels: productModels,
    product: product,
    productFamilies: productFamilies,
    customers: customers,
    companies: companies,
    companiesTypes: companiesTypes,
    units: units,
    parts: parts,
    problemTypes: problemTypes,
    resolutionCategories: resolutionCategories,
    administrators: administrators,
    sac: sac,
    technicians: technicians,
    photo: photo,
    taskTypes: taskTypes,
    serviceOrders: serviceOrders,
    search: search,
    filterProductModel: filterProductModel,
    tickets: tickets,
    ticketsCategories: ticketsCategories,
    ticketsResponses: ticketsResponses,
    ticketCC: ticketsCC,
    macros: macros,
    alerts: alerts,
    states: states,
    cities: cities,
    issues: issues,
    solutions: solutions,
    email: email,
    notifications: notifications,
    reseller: reseller,
    readonly: readonly,
  });
};
export const asyncNotificationsActions = {
  get: getTicketsNotifications,
  post: postTicketNotifications
}
export const asyncSearchActions = {
  getStates: getStates,
  getCities: getCities
};

export const asyncProductModelActions = {
  getProductModels,
  deleteProductModel,
  editProductModel,
  createProductModel,
};

export const asyncProductActions = {
  getProduct,
  deleteProduct,
  editProduct,
  createProduct,
};

export const asyncProductFamiliesActions = {
  getProductFamilies,
  deleteProductFamilies,
  editProductFamilies,
  createProductFamilies,
};

export const asyncCustomersActions = {
  getCustomers,
  deleteCustomers,
  editCustomers,
  createCustomers,
};

export const asyncCompaniesActions = {
  getCompanies,
  deleteCompanies,
  editCompanies,
  createCompanies,
  getCompaniesTypes
};

export const asyncUnitsActions = {
  getUnits,
  deleteUnits,
  editUnits,
  createUnits,  
  getUnitsFromCompany,
};

export const asyncPartsActions = {
  getParts,
  deleteParts,
  editParts,
  createParts,
};

export const asyncProblemTypesActions = {
  get: getProblemTypes,
  delete: deleteProblemTypes,
  edit: editProblemTypes,
  create: createProblemTypes,
};

export const asyncResolutionCategoriesActions = {
  get: getResolutionCategories,
  delete: deleteResolutionCategories,
  edit: editResolutionCategories,
  create: createResolutionCategories
}

export const asyncUserAdministradorActions = {
  get: getAdministrators,
  delete: deleteAdministrators,
  edit: editAdministrators,
  create: createAdministrators
}

export const asyncUserSacActions = {
  get: getSac,
  delete: deleteSac,
  edit: editSac,
  create: createSac,
}

export const asyncUserTechniciansActions = {
  get: getTechnicians,
  delete: deleteTechnicians,
  edit: editTechnicians,
  create: createTechnicians
}

export const asyncUploadPhotoActions = {
  post: uploadPhoto
}

export const asyncTaskTypesActions = {
  get: getTaskTypes,
  delete: deleteTaskTypes,
  edit: editTaskTypes,
  create: createTaskTypes
}

export const asyncServiceOrdersActions = {
  cancel: cancelServiceOrder,
  get: getServiceOrder,
  reopen: reopenServiceOrder,
  create: createServiceOrder,
  edit: editServiceOrder,
}

export const asyncFilterActions = {
  getProductModelsFilter: getProductModelsFilter
}

export const asyncTicketsActions = {
  get: getTickets,
  delete: deleteTickets,
  edit: editTickets,
  create: createTickets,
  getSingle: getSingleTicket,
  ccUsers: getTicketsCcUsers,
  getByCompany: getTicketsFromCompany,

}
export const asyncTicketsCategoriesActions = {
  get: getTicketsCategories,
  delete: deleteTicketsCategories,
  edit: editTicketsCategories,
  create: createTicketsCategories
}

export const asyncTicketsResponsesActions = {
  get: getTicketsResponses,
  post: postTicketsResponse
}

export const asyncMacrosActions = {
  get: getMacros,
  delete: deleteMacros,
  edit: editMacros,
  create: createMacros
}

export const asyncIssuesActions = {
  get: getIssues,
  delete: deleteIssues,
  edit: editIssues,
  create: createIssues
}

export const asyncSolutionsActions = {
  get: getSolutions,
  delete: deleteSolutions,
  edit: editSolutions,
  create: createSolutions
}

export const asyncResellersActions = {
  get: getResellers,
  delete: deleteResellers,
  edit: editResellers,
  create: createResellers
}

export const asyncGetEmail = {
  get: getEmail
}

export const asyncReadonlyActions = {
  get: getReadonly,
  delete: deleteReadonly,
  edit: editReadonly,
  create: createReadonly
}

export default exportReducers;
