  import React, { useEffect } from "react";
  import { Content } from "./style";
  import { Stack } from "@mui/material";

  
  const WhatsappImport = () => {
  
  
    return (
      <Content>
        
          <Stack style={{ marginTop: 10 }}>
                Teste de Endpoint
          </Stack>
        
      </Content>
    );
  };
  
  export default WhatsappImport;
  