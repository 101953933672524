import React from 'react';
import {useJumboApp} from "@jumbo/hooks";
import { LAYOUT_NAMES } from 'app/layouts/layouts';
import {
    Chip,
    Pagination,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Button,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Popover,
    Stack,
    Typography
} from "@mui/material";
import { useState } from 'react';
import ProjectsList from './components/ProjectsList';
import { useParams } from 'react-router-dom';
import ChamadosSkeleton from './Skeleton';
import JumboSearch from '@jumbo/components/JumboSearch';
import Div from '@jumbo/shared/Div';
import SortIcon from "@mui/icons-material/Sort";
import { history } from 'app/redux/store';
import { appTheme } from 'app/themes/app/theme';
import { Content } from './style';
import api from "../../services/config";
import { useDispatch, useSelector } from 'react-redux';
import { CLEAN_SERVICE_ORDERS_STORE } from 'app/redux/modules/ServiceOrders/constants';

const ChamadosTecnicos = ({ companyId }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [order, setOrder] = useState("asc");
    const [paramsOrder, setParams] = useState({});
    const [continueFetching, setContinueFetching] = useState(true);
    const [novoChamado, setNovoChamado] = useState(false);

    const [serviceOrdes, setServiceOrdes] = useState([]);

    const {auth} = useSelector(state => state);

    const fields = [
        { name: "Ordem de Serviço", value: "so" },
        { name: "Nota Fiscal", value: "invoice" },
        { name: "Data de Abertura", value: "created_at" },
        { name: "Data de Resolução", value: "resolution_at" },
        { name: "Descrição", value: "description" },
        /* { name: "Tipo de Serviço", value: "" }, */
    ];
    
    const {setActiveLayout} = useJumboApp();
    React.useEffect(() => {
        setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
    }, []);

    /* const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }; */

    async function getOrders() {
        setLoading(true);

        try {
            const params = {
                ...paramsOrder,
                orderBy: paramsOrder.orderBy ? `${paramsOrder.orderBy},${order}` : ""
            }

            const { data } = await api.get(`${companyId ? `companies/${companyId}/service-orders` : "service-orders"}?page=${page}`, {
                params,
                headers: {
                    Authorization: `Bearer ${auth.token}`
                }
            });

            setServiceOrdes(data.data);
            setTotalPages(data.meta.last_page);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    React.useEffect(() => {
        getOrders();
    }, [page, paramsOrder, order]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    function toggleOrder() {
        setOrder(order == "asc" ? "desc" : "asc");
    }

    return (
        <Content>
            {/* <ModalNovoChamado
                open={novoChamado}
                setOpen={setNovoChamado}
            /> */}

            <Div>
                <Div className="flex min-[1650px]:flex-row flex-col py-4 justify-between">
                    <Typography className="flex items-center" variant={'h2'}>Lista de Ordens de Serviço</Typography>        
                    
                    {
                        !companyId && (
                            <Div className="flex flex-wrap xl:flex-row items-center">
                                <Div className="filter-item sm:flex hidden">
                                    <FormControl className="m-2 2xl:min-w-[200px] min-w-[142px]" sx={{minWidth: 200}} size="small">
                                        <InputLabel id="demo-select-small-status">
                                            Status
                                        </InputLabel>
                                        <Select
                                            labelId="demo-select-small-status"
                                            id="demo-select-small-status"
                                            value={paramsOrder.status_service_order_id}
                                            label="Status"
                                            onChange={(event) => {
                                                setParams({
                                                    ...paramsOrder,
                                                    status_service_order_id: event.target.value
                                                })
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            <MenuItem value={1}>Aberto</MenuItem>
                                            <MenuItem value={2}>À caminho</MenuItem>
                                            <MenuItem value={3}>Em atendimento</MenuItem>
                                            <MenuItem value={4}>Resolvido</MenuItem>
                                            <MenuItem value={5}>Pendente</MenuItem>
                                            <MenuItem value={6}>Cancelado</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Div>
                                
                                <Div className="filter-item sm:flex hidden mr-2">
                                    <FormControl className="m-2 2xl:min-w-[200px] min-w-[142px]" sx={{minWidth: 250}} size="small">
                                        <InputLabel id="demo-select-small-order">Ordenar por</InputLabel>
                                        <Select
                                            labelId="demo-select-small-order"
                                            id="demo-select-small-order"
                                            value={paramsOrder.orderBy}
                                            label="Order"
                                            onChange={(event) => {
                                                setParams({
                                                    ...paramsOrder,
                                                    orderBy: event.target.value
                                                })
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {
                                                fields.map(field => <MenuItem value={field.value}>{field.name}</MenuItem>)
                                            }
                                            {/* <MenuItem value="equipamento_nome">Máquina</MenuItem>
                                            <MenuItem value="tempo_chamado">Tempo</MenuItem>
                                            <MenuItem value="tecnico_id">Técnico</MenuItem>
                                            <MenuItem value="created_at">Data de Abertura</MenuItem>
                                            <MenuItem value="client">Solicitante</MenuItem>
                                            <MenuItem value="empresa">Empresa</MenuItem>
                                            <MenuItem value="ocorrencia_reportada">Ocorrência</MenuItem> */}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        sx={{
                                            m: 1,
                                            borderColor: appTheme.palette.red100,
                                            color: appTheme.palette.red100,
                                            '&:hover': {
                                                borderColor: appTheme.palette.red200,
                                            }
                                        }}
                                        variant='outlined'
                                        onClick={toggleOrder}
                                    >
                                        <SortIcon sx={{ transform: `rotateX(${order == "asc" ? 0 : 180}deg)` }} />
                                    </Button>
                                </Div>
                                
                                <FormControl className="min-[1500px]:w-[212px] w-[130px]">
                                    <JumboSearch
                                        onChange={(e) => {
                                            setContinueFetching(false);
                                            setPage(1);
                                            setContinueFetching(true);
                                            setParams({
                                                ...paramsOrder,
                                                s: e
                                            });
                                        }}
                                        placeholder="Pesquisar"
                                        sx={{
                                            display: { md: 'block' },
                                            flex: 1,
                                            bgcolor: "#FFF"
                                        }}
                                    />
                                </FormControl>

                                <Button
                                    variant="contained"
                                    color="success"
                                    sx={{
                                        ml: 3
                                    }}
                                    onClick={() => {
                                        dispatch({ type: CLEAN_SERVICE_ORDERS_STORE });
                                        history.push("/os/novo")
                                    }}
                                >
                                    Nova ordem de serviço
                                </Button>
                            </Div>
                        )
                    }
                </Div>
            </Div>

            {
                loading 
                ? (
                    <ChamadosSkeleton />
                )
                : (
                    <>
                        <Stack style={{ marginTop: 10 }}>
                            <ProjectsList data={serviceOrdes} />
                            <Pagination
                                count={totalPages}
                                page={page}
                                onChange={(event, value) => setPage(value)}
                                shape="rounded"
                                variant="outlined"
                                color="secondary"
                            />
                        </Stack>
                    </>
                )
            }
        </Content>
    );
};

export default ChamadosTecnicos;