import {
  SET_SEARCH,
  CLEAN_SEARCH,
  SET_SEARCH_STATUS,
  SET_SEARCH_CATEGORY,
  SET_SEARCH_ORDER,
  SET_SEARCH_ORDER_BY,
  SET_SEARCH_PAGE,
  SET_RESPONSE_TICKET,
  SET_PRODUCT_FAMILY_ID,
  SET_SEARCH_CITY,
  SET_SEARCH_UF,
  SET_COMPANY_TYPE_ID,
} from "./constants";

export const setProductFamilyId = (payload) => ({
  type: SET_PRODUCT_FAMILY_ID,  
  payload: payload,
});

export const setCompanyTypeId = (payload) => ({
  type: SET_COMPANY_TYPE_ID,
  payload: payload
})

export const setCity = (payload) => ({
  type: SET_SEARCH_CITY,  
  payload: payload,
});

export const setUf = (payload) => ({
  type: SET_SEARCH_UF,  
  payload: payload,
});


export const setCategory = (payload) => ({
  type: SET_SEARCH_CATEGORY,  
  payload: payload,
});
export const setStatus= (payload) => ({
  type: SET_SEARCH_STATUS,  
  payload: payload,
});

export const setOrderBy = (payload) => ({
  type: SET_SEARCH_ORDER_BY,  
  payload: payload,
});

export const setOrder = (payload) => ({
  type: SET_SEARCH_ORDER,  
  payload: payload,
});
export const setPage = (payload) => ({
  type: SET_SEARCH_PAGE,  
  payload: payload,
});

export const setTicketResponse = (payload) => ({
  type: SET_RESPONSE_TICKET,  
  payload: payload,
});

export const cleanSearch = () => ({
  type: CLEAN_SEARCH,
});