//CUSTOMERS Types

export const GET_CUSTOMERS_REQUEST = "GET_CUSTOMERS_REQUEST";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";

export const GET_SINGLE_CUSTOMERS_REQUEST = 'get-single-CUSTOMERS-request'
export const GET_SINGLE_CUSTOMERS_FAILURE = 'get-single-CUSTOMERS-failure';
export const GET_SINGLE_CUSTOMERS_SUCCESS = 'get-single-CUSTOMERS-success';

export const POST_CUSTOMERS_REQUEST = "POST_CUSTOMERS_REQUEST";
export const POST_CUSTOMERS_FAILURE = "post-CUSTOMERS-failure";
export const POST_CUSTOMERS_SUCCESS = "post-CUSTOMERS-success";

export const PUT_CUSTOMERS_REQUEST = "PUT_CUSTOMERS_REQUEST";
export const PUT_CUSTOMERS_FAILURE = "put-CUSTOMERS-failure";
export const PUT_CUSTOMERS_SUCCESS = "put-CUSTOMERS-success";

export const DELETE_CUSTOMERS_REQUEST = "DELETE_CUSTOMERS_REQUEST";
export const DELETE_CUSTOMERS_FAILURE = "delete-CUSTOMERS-failure";
export const DELETE_CUSTOMERS_SUCCESS = "delete-CUSTOMERS-success";

export const CLEAN_CUSTOMERS_STORE = "CLEAN_CUSTOMERS_STORE";