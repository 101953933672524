export const ADDRESS_INPUT = "address"
export const MASKED_INPUT = "masked"
export const TEXT_INPUT = "text"
export const SELECT_AUTOCOMPLETE_INPUT = "select"
export const STATUS_INPUT = "status"
export const PASSWORD_INPUT = "password"

export const DATA_TYPE_ID = "id"
export const DATA_TYPE_STRING = "string"
export const DATA_TYPE_CHIP = "chip"
export const DATA_TYPE_DATE = "date"
export const PHOTO_UPLOAD_INPUT = "PHOTO_UPLOAD_INPUT"
export const UNIT_INPUT = "UNIT_INPUT"
export const UF_INPUT = "UF_INPUT"
export const CITY_INPUT = "CITY_INPUT"
export const EMAIL_INPUT = "EMAIL_INPUT"

