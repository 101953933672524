import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "./style";
import { Stack } from "@mui/material";
import DynamicViewGenerator from "app/components/DynamicViewGenerator/DynamicViewGenerator";

import { asyncCompaniesActions, asyncUnitsActions } from "app/redux/reducers";
import { CLEAN_COMPANIES_STORE } from "app/redux/modules/Customers/Companies/constants";
import { ADDRESS_INPUT, DATA_TYPE_CHIP, DATA_TYPE_STRING, MASKED_INPUT, SELECT_AUTOCOMPLETE_INPUT, STATUS_INPUT, TEXT_INPUT } from "app/components/DynamicViewGenerator/constants";
import { CLEAN_UNITS_STORE } from "app/redux/modules/Customers/Units/constants";
import * as Yup from "yup";

const Units = ({ companyId }) => {
  const dispatch = useDispatch();
  const { companies, units, auth } = useSelector((state) => state);
  const [newSelectOptions, setNewSelectOptions] = React.useState([]);

  const startPage = () => {
    if(companyId) {
      dispatch(asyncUnitsActions.getUnitsFromCompany(companyId));
    } else {
      dispatch(asyncUnitsActions.getUnits("asc","id"))
      dispatch(asyncCompaniesActions.getCompanies("asc", "id","1",""," ",true));
    }
  };
  useEffect(() => {
    startPage();
    return () => {
      dispatch({ type: CLEAN_COMPANIES_STORE });
    };
  }, [dispatch]);

  useEffect(() => {
    if (companies.data) {
      setNewSelectOptions(
        companies.data.data &&
        companies.data.data.map(({ id, name }) => ({ id, name }))
      );
    }
  }, [companies]);

  const dataLabels = [
    {
      id: "Código",
      name: "Nome",
      description: "Descrição",
      address: "Endereço",
      address_add_info: "Complemento",
      company_id: "Empresa",    
      status: "Status",
    },
  ];

  const dataType = [
    {
      id: DATA_TYPE_STRING,
      name: DATA_TYPE_STRING,
      description: DATA_TYPE_STRING,
      address: DATA_TYPE_STRING,
      address_add_info: DATA_TYPE_STRING,
      company_id: {
        parent: "company",
        name: "name",
      },     
      status: DATA_TYPE_CHIP,
    },
  ];

  const formFieldsType = [
    {
      type: TEXT_INPUT,
      value: "name",
      label: "Nome",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: TEXT_INPUT,
      value: "description",
      label: "Descrição",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: SELECT_AUTOCOMPLETE_INPUT,
      value: "company_id",
      label: "Empresa",
      required: true,
      options: newSelectOptions,
      action: "",
      parent: "",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: TEXT_INPUT,
      value: "classification",
      label: "Classificação",
      required: false,
      action: "",
      validation: "",
    },
    {
      type: ADDRESS_INPUT,
      value: "address",
      label: "Endereço",
      required: true,
      action: "",
    },  
    {
      type: TEXT_INPUT,
      value: "address_add_info",
      label: "Complemento",
      required: false,
      action: "",
      validation: ""
    },
   
    {
      type: STATUS_INPUT,
      value: "status",
      label: "Status",
      required: true,
      action: "",
      parent: "",
      validation: Yup.string().required("Obrigatório")
    },
  ];

  const pageConfig = {
    title: "Unidades",
    newText: "Nova unidade",
    searchText: "Pesquisar",
    hiddenSearch: companyId ? true : false,
    hiddenNewButton: companyId ? true : false,
    hiddenSort: companyId ? true : false,
    data: units.data,
    loading: units.loading,
    company: companyId,
    actions: {
      delete: asyncUnitsActions.deleteUnits,
      edit: asyncUnitsActions.editUnits,
      get: asyncUnitsActions.getUnits,
      search: asyncUnitsActions.getUnits,
      create: asyncUnitsActions.createUnits,
      clean: { type: CLEAN_UNITS_STORE },
    },
    createDataType: formFieldsType,
  };

  return (
    <Content>
      {
        <Stack style={{ marginTop: 10 }}>
          <DynamicViewGenerator
            pageConfig={pageConfig}
            route={"modelos"}
            type={dataType}
            labels={dataLabels}
          />
        </Stack>
      }
    </Content>
  );
};

export default Units;
